import { ACTION_TYPE } from '.';

export type SetActivePromotedItemActionType = {
    type: ACTION_TYPE.SET_ACTIVE_PROMOTED_ITEM,
    activePromotedItem: string
};

export function setActivePromotedItem(activePromotedItem: string): SetActivePromotedItemActionType {
    return {
        type: ACTION_TYPE.SET_ACTIVE_PROMOTED_ITEM,
        activePromotedItem
    };
}
