type Validator = {
    isValidate: boolean;
    errors: string[]
};

export const validator = (rules: Function[]) => (value: string): Validator => {
    const errors = rules
        .map((rule) => rule(value))
        .filter((rule) => typeof rule === 'string');
    const isValidate = (errors.length === 0);
    return {
        isValidate,
        errors
    };
};
