import { APP_GLOBAL_STATE, AppStorageDataType, QueryParams } from './app-storage-data-type';
import DebugMode from '../services/debug-mode';
import { getParameterByName } from '../helpers/url-helpers';
import AccessCache from 'app/services/access-cache';

export const getInitAppData = (): AppStorageDataType => {

    let state: AppStorageDataType = {
        pageTitle: 'init value',

        // Wyjątkowo pobieramy datę z przeglądarki a nie z serwisu do tego przeznaczonego
        // Ponieważ ten serwis używa stora do pobrania informacji do debugMode
        // i powstawała by pętla wywołań
        now: Date.now(),

        appState: APP_GLOBAL_STATE.INIT_APP,
        promotedItems: [],
        showGlobalSpinner: false,
        debugMode: DebugMode.isDebugActive(),
        debugSettings: DebugMode.getDebugSettings(),
        isTestingPanelVisible: false,
        accessCache: AccessCache.getAccessCache(),
        extraPriorities: []
    };

    state.queryParams = getQueryParams();

    if (getParameterByName('testmode')) {
        state.debugMode = true;
        history.replaceState({}, null, window.location.href.replace(new RegExp('([?&])testmode=([^&]*)'), '$1'));
    }

    return state;
};

/**
 * Dodaje do state dane zawatre w parametrach strony
 */
function getQueryParams(): Partial<QueryParams> {

    // autoplay - czy po uruchomieniu strony ma się uruchomić player
    // Każdą wartość autoplay uważamy na pozytywną
    let autoplayParam = getParameterByName('autoplay');
    let autoplay = autoplayParam === null ? null : Boolean(autoplayParam);

    // id - ID elementu który ma być domyslnie aktywny
    let id: string = getParameterByName('id');

    // status - informacja czy mamy dostep do elementu o ID podanym w parametrze id
    let buyStatus: string = getParameterByName('buyStatus');
    let hasAccess: boolean;
    switch (buyStatus) {
        case IPLA_TV_STATUS.OK:
            hasAccess = true;
            break;

        case IPLA_TV_STATUS.FAIL:
            hasAccess = false;
            break;

        default:
            hasAccess = null;
    }

    // Jeżeli nie było żadnego
    if (autoplay !== null || id !== null || buyStatus !== null) {
        let queryParams: Partial<QueryParams>;

        if (id !== null) {
            queryParams = queryParams || {};
            queryParams.id = id;
        }

        if (autoplay !== null) {
            queryParams = queryParams || {};
            queryParams.autoplay = autoplay;
        }

        if (hasAccess !== null) {
            queryParams = queryParams || {};
            queryParams.hasAccess = hasAccess;
        }

        // Usuwamy parametr buyStatus z adresu aby po odświerzeniu strony nie mieć założeń nt. dosepu do materiału
        history.replaceState({}, null, window.location.href.replace(new RegExp('([?&])buyStatus=([^&]*)'), '$1'));

        return queryParams;
    } else {
        // noop
    }
}

enum IPLA_TV_STATUS {
    OK = 'ok',
    FAIL = 'fail'
}
