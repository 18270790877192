import * as React from "react";
import { initPlayer } from "app/storage/actions/init-player";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppStorageDataType } from "app/storage/app-storage-data-type";
import { showTokenForm } from "app/storage/actions/show-token-form";
import { PromotedItemType } from "app/config-object-type";
import { setActivePromotedItem } from "app/storage/actions/set-active-promoted-item";
import Stats from "app/services/stats";
import { getPaymentUrl } from "app/helpers/ipla-url-helper";
import { scrollToPosY } from "app/helpers/scroll-helper";
import classNames from "classnames";
import isPlayable from "app/storage/selectors/is-playable";
import hasAccess from "app/storage/selectors/has-access";
import isFree from "app/storage/selectors/is-free";

interface ConnectedState {
  isPlayable: boolean;
  hasAccess: boolean;
  isFree: boolean;
}

interface ConnectedDispatch {
  initPlayer(promotedItemId: string): void;
  openTokenForm(promotedItemId: string): void;
  setActivePromotedItem(idItem: string): void;
}

interface OwnProps {
  promotedItem: PromotedItemType;
  btnSize?: string;
  hideTokenBtn?: boolean;
}

interface OwnState {}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class ActionsButtonsComponent extends React.Component<
  ComponentProps,
  OwnState
> {
  public static defaultProps = {
    btnSize: "big",
    hideTokenBtn: false,
  };

  public render() {
    const {
      promotedItem,
      initPlayer,
      openTokenForm,
      btnSize,
      isPlayable,
      hasAccess,
      isFree,
      hideTokenBtn,
    } = this.props;

    let showPlayButton = isPlayable || promotedItem.isPlayerShowed;

    let showBuyButton =
      promotedItem.isPlayerShowed || promotedItem.limitExceeded || hasAccess
        ? false
        : promotedItem.accessByToken || promotedItem.accessByPacks;

    let showTokenButton =
      !hideTokenBtn &&
      !promotedItem.isPlayerShowed &&
      !hasAccess &&
      !isFree &&
      promotedItem.accessByToken;

    let classPlayButton = classNames({
      [`button-${btnSize}-round`]: true,
      [`button-${btnSize}-round--active`]: promotedItem.isPlayerShowed,
    });

    return (
      <div className="actions">
        {showPlayButton && (
          <button
            className={classPlayButton}
            onClick={(event) => {
              event.stopPropagation();
              if (promotedItem.isPlayerShowed) {
                return;
              }

              this.props.setActivePromotedItem(promotedItem.id);
              scrollToPosY(document.getElementsByTagName("html")[0], 0);
              initPlayer(promotedItem.id);
            }}
          >
            <i className="icon icon-play"></i>
            <p className={`button-${btnSize}-round__label`}>
              {promotedItem.isPlayerShowed ? "oglądasz" : "oglądaj"}
            </p>
          </button>
        )}
        {showBuyButton && (
          <button
            className={`button-${btnSize}-round`}
            onClick={(event) => {
              event.stopPropagation();
              Stats.instance.buyButtonClicked(promotedItem.title).then(() => {
                window.location.href = getPaymentUrl(promotedItem);
              });
            }}
          >
            <i className="icon icon-cart"></i>
            <p className={`button-${btnSize}-round__label`}>kupuję</p>
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: AppStorageDataType,
  props: OwnProps
): ConnectedState => {
  return {
    isPlayable: isPlayable(state)(props.promotedItem.id),
    hasAccess: hasAccess(state)(props.promotedItem.gmID),
    isFree: isFree(state)(props.promotedItem.id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
  return {
    initPlayer(id: string): void {
      dispatch(initPlayer(id));
    },
    openTokenForm(id: string): void {
      dispatch(showTokenForm(id));
    },
    setActivePromotedItem(id: string): void {
      dispatch(setActivePromotedItem(id));
    },
  };
};

const ActionsButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsButtonsComponent);

export default ActionsButtons;
