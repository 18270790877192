import * as React from 'react';
import { ChangeEvent } from 'react';

interface Props {
    options: Array<Option>;
    name: string;
    classWrapper?: string;
    defaultValue?: string | null;
    showLabel?: boolean;
    labelClassName?: string;
    labelValue?: string;
    selectClassName?: string;
    disabled?: boolean;
    onChange?( evt: ChangeEvent<HTMLSelectElement> ): void;
}

interface Option {
    optionClassName?: string;
    optionValue: string;
    optionLabel?: string | null;
    optionSelected?: boolean | null;
}

const Select: React.StatelessComponent<Props> = ( props ) => {
    const renderOption = ( option: Option, index: number ) => {
        return (
            <option
                className={option.optionClassName}
                value={option.optionValue}
                key={index}
            >
                {option.optionLabel}
            </option>
        );
    };

    return (
        <div className={props.classWrapper}>
            {
                props.showLabel &&
                <label
                    className={props.labelClassName}
                >
                    {props.labelValue}
                </label>
            }
            <select
                name={props.name}
                value={props.defaultValue}
                className={props.selectClassName}
                onChange={props.onChange}
            >
                {props.options.map( renderOption )};
            </select>
        </div>
    );
};

export default Select;
