import { ACTION_TYPE } from '.';

export type ShowTestingPanelActionType = {
    type: ACTION_TYPE.SHOW_TESTING_PANEL,
};

/**
 * Akcja pokazująca panel z formularzem do wprowadzenia danych testowych
 */
export function showTestingPanel(): ShowTestingPanelActionType {
    return {
        type: ACTION_TYPE.SHOW_TESTING_PANEL
    };
}
