import { ConfigObjectType } from '../config-object-type';
import { defaultConfig } from '../default-config';
import { HttpService } from './http/http-service';
import defaultsDeep from 'lodash.defaultsdeep';
import { ExternalConfigObjectType } from 'app/storage/actions/update-config';

const CONFIG_URI = './config.json';

/**
 * Zwraca obiekt konfiguracyjny strony.
 *
 * @export
 * @returns {Promise<ConfigObjectType>}
 */
export async function getConfig(): Promise<ExternalConfigObjectType> {
    return new Promise<ExternalConfigObjectType>(async (resolve, reject) => {
        try {
            const loadedConfig: ConfigObjectType = await loadConfigJSON();
            resolve(defaultsDeep({}, defaultConfig, loadedConfig));
        } catch (e) {
            resolve(defaultConfig);
        }
    });
}

/**
 * Pobiera konfigurację z pliku `config.json`
 */
async function loadConfigJSON(): Promise<ConfigObjectType> {
    return (new HttpService()).request(CONFIG_URI).then(response => {
        return response.data;
    }).catch(() => {
        return {};
    });
}
