import getNow from './get-now';
import { AppStorageDataType } from '../app-storage-data-type';
import customCreateSelector from './custom-create-selector';

/**
 * Pobieranie aktywnych extraPrioriteis,
 * czyli takich gdzie aktualna data mieści się pomiędzy promoteStart i promoteEnd
 */
const getActiveExtraPriorities = customCreateSelector(
    [
        getNow,
        (state: AppStorageDataType) => state.extraPriorities
    ],
    (now, extraPriorities) => {
        const items =
            extraPriorities
                .filter(
                    (item) => {
                        const itemPromoteStart = new Date(item.promoteStart).getTime();
                        const itemPromoteEnd = new Date(item.promoteEnd).getTime();

                        return itemPromoteStart <= now && itemPromoteEnd >= now;
                    }
                );

        return items;
    }
);

export default getActiveExtraPriorities;
