import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Layout from './state-less/layout-partial';
import PageLoaderContainer from './state-store/page-loader-container';
import PromotedItemList from './state-store/promoted-items-list';
import TestingMode from './state-store/testing-mode';
import { PromotedItemType } from '../config-object-type';
import { APP_GLOBAL_STATE, AppStorageDataType } from '../storage/app-storage-data-type';
import { getConfig } from '../services/get-config';
import { updateConfig } from '../storage/actions/update-config';
import { setUser } from '../storage/actions/set-user';
import cookiesService from '../services/cookies';
import MainPromotedItemComponent from './state-full/main-promoted-item';
import getItemsListToShow from '../storage/selectors/get-item-list-to-show';
import getActiveItem from 'app/storage/selectors/get-active-item';
import { fetchServerTime } from '../services/fetch-server-time';

interface ConnectedState {
    promotedItemsList: PromotedItemType[];
    appState: APP_GLOBAL_STATE;
    activePromotedItem?: PromotedItemType;
}

interface ConnectedDispatch {
    bootstrapApp(): void;
    setupUser(): void;
}

type ComponentProps = ConnectedState & ConnectedDispatch;

class AppContainer extends React.Component<ComponentProps> {
    public componentDidMount() {
        this.props.bootstrapApp();
        this.props.setupUser();
    }

    public render() {
        const { promotedItemsList, appState, activePromotedItem } = this.props;
        return (
            <Layout>
                <PageLoaderContainer appState={appState} />
                {
                    activePromotedItem &&
                    <MainPromotedItemComponent
                        media={activePromotedItem}
                    />
                }
                {
                    appState === APP_GLOBAL_STATE.ITEMS_LIST &&
                    <PromotedItemList promotedItemsList={promotedItemsList} />
                }
                <TestingMode />
            </Layout>
        );
    }
}

const mapStateToProps = (state: AppStorageDataType): ConnectedState => {
    return {
        promotedItemsList: getItemsListToShow(state),
        appState: state.appState,
        activePromotedItem: getActiveItem(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        bootstrapApp: async () => {
            await fetchServerTime();
            const config = await getConfig();
            dispatch(updateConfig(config));
        },

        setupUser: () => {
            const sessionString = cookiesService.get(cookiesService.COOKIES_NAME.SESSION);
            const userRecognition = cookiesService.getAsObject(cookiesService.COOKIES_NAME.USER_RECOGNITION);

            if (!sessionString || !userRecognition) {
                return;
            }

            let session;

            try {
                session = JSON.parse(sessionString);
            } catch (err) {
                session = null;
            }

            if (!session) {
                return;
            }

            const user = {
                session,
                ...userRecognition
            };

            dispatch(setUser(user));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
