import getNow from './get-now';
import getItemsList from './get-items-list';
import getExtraPriority from './get-extra-prioryty';
import getDebugSettings from './get-debug-settings';
import getDebugMode from './get-debug-mode';
import customCreateSelector from './custom-create-selector';

/**
 * Zwraca przefiltrowaną i posortowaną listę elementów promowanych
 */
const getItemsListToShow = customCreateSelector(
    [
        getNow,
        getItemsList,
        getExtraPriority,
        getDebugSettings,
        getDebugMode
    ],
    (
        now,
        items,
        getExtraPriority,
        debugSettings,
        debugMode
    ) => {
        return items
            .filter((item) => {
                // Jeżeli user wszedł w interakcje z elementem to nie pozwalamy żeby element tak poprostu zniknął.
                if (item.isPlayerShowed || item.isFormTokenShowed) {
                    return true;
                }

                // Jeżeli włączona jest opcja showAllData w trybie debugowania to pokaż wszystkie elementy
                if (debugSettings.showAllData && debugMode) {
                    return true;
                }

                /**
                 * Jeżeli w trybie debugowania podany jest ID materiału to pokaż tylko ten materiał
                 * Jeżeli ID jest nieprawidłowe to nic nie wyświetlaj (data i czas nie jest brana pod uwagę)
                 */
                if (debugSettings.mediaId && debugMode) {
                    return debugSettings.mediaId === item.id;
                }

                return (new Date(item.promoteStart)).getTime() < now && (new Date(item.promoteEnd)).getTime() > now;
            })
            // tslint:disable-next-line:cyclomatic-complexity
            .sort((a, b) => {
                // Element znajdujący się w extraPriorities wyświetlany jest pierwszy
                const extraPriorityA = getExtraPriority(a.id);
                const extraPriorityB = getExtraPriority(b.id);

                if (extraPriorityA < extraPriorityB) return 1;
                if (extraPriorityA > extraPriorityB) return -1;

                // Element o wyższej wartości `priority` wyświetlany jest pierwszy
                if ((a.priority || 0) < (b.priority || 0)) return 1;
                if ((a.priority || 0) > (b.priority || 0)) return -1;

                // Element o wyższej wartości `priority` wyświetlany jest pierwszy
                if ((a.priority || 0) < (b.priority || 0)) return 1;
                if ((a.priority || 0) > (b.priority || 0)) return -1;

                // Element o wcześniejszej dacie staru promowania wyświetla się pierwszy
                let promoteStartA = new Date(a.promoteStart);
                let promoteStartB = new Date(b.promoteStart);

                if (promoteStartA < promoteStartB) return -1;
                if (promoteStartA > promoteStartB) return 1;

                // Element o wcześniejszej dacie staru odtwarzania wyświetla się pierwszy
                if (a.playableStart && b.playableStart) {
                    let playableStartA = new Date(a.playableStart);
                    let playableStartB = new Date(b.playableStart);

                    if (playableStartA < playableStartB) return -1;
                    if (playableStartA > playableStartB) return 1;
                }

                // Kiedy oba elementy mają te same daty playableStart, decyduje klucz order
                if ((a.order || 0) < (b.order || 0)) return -1;
                if ((a.order || 0) > (b.order || 0)) return 1;

                // Liczy się kolejność w tablicy
                let indexA = items.indexOf(a);
                let indexB = items.indexOf(b);

                if (indexA < indexB) return -1;
                if (indexA > indexB) return 1;

                return 0;
            });
    }
);
export default getItemsListToShow;
