import * as crypto from 'crypto-js';

const ONE_SECOND = 1000;

export interface SessionInterface {
    readonly id: string;
    readonly key: string;
    readonly keyExpirationTime: number;
}

export interface RpcSessionKeyInterface {
    readonly $super: $SuperInterface;
    readonly sigBytes: number;
    readonly words: Array<number>;
}

interface $SuperInterface {
    clamp: Function;
    clone: Function;
    concat: Function;
    init: Function;
    random: Function;
    toString: Function;
}

export function encodeSessionKey(key: string): RpcSessionKeyInterface {
    return crypto.enc.Base64.parse(urlSafeDecode(key));
}

export function translateUnixTimestampToDate(sec: number): Date {
    return new Date(sec * ONE_SECOND);
}

export function calculateSessionRefreshTime(now: Date, expirationTime: number): number {
    const expirationMillis = expirationTime * ONE_SECOND;
    const currentMillis = now.getTime();
    const diffExpirationCurrent = expirationMillis - currentMillis;
    const millisToRefresh = Math.round(diffExpirationCurrent / 2);
    return (currentMillis + millisToRefresh);
}

export function urlSafeEncode(input: string): string {
    return input.replace(/\+/g, '-').replace(/\//g, '_');
}

export function urlSafeDecode(input: string): string {
    return input.replace(/-/g, '+').replace(/_/g, '/');
}

export function generateSessionRpcKey(sessionRpcMethodString: string, key: RpcSessionKeyInterface): string {
    // Generacja podpisu zapytania
    const rpcMethodDigest = crypto.HmacSHA256(sessionRpcMethodString, key);

    // Kodowanie podpisu do postaci przesyłalnej.
    const rpcKey = rpcMethodDigest.toString(crypto.enc.Base64);

    return urlSafeEncode(rpcKey);
}

export function createSessionToken(rpcSessionData: SessionInterface, method: string, namespace: string): string {
    // Jak nie mamy danych sesji to nie mamy na bazie czego budować tokena.
    if (!rpcSessionData) {
        return null;
    }

    // Budujemy string z metadanymi zapytania.
    const sessionRpcMethodString =
        `${rpcSessionData.id}|${rpcSessionData.keyExpirationTime}|${namespace}|${method}`;

    // Odkodowanie klucza sesji.
    const key = encodeSessionKey(rpcSessionData.key);

    // Generacja podpisu zapytania.
    const sessionRpcKey = generateSessionRpcKey(sessionRpcMethodString, key);

    // Wynikowy authToken.
    return `${sessionRpcMethodString}|${sessionRpcKey}`;
}
