import Cookies from 'universal-cookie';

interface CookiesOptionsInterface {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
}

enum COOKIES_NAME {
    AUTH_TOKEN = 'user-auth-token',
    USER_RECOGNITION = 'user-recognition',
    SESSION = 'user-session'
}

export default (() => {
    const cookies = new Cookies();

    return {
        COOKIES_NAME,

        get(name: string): string {
            return cookies.get(name, { doNotParse: true });
        },

        getAsObject(name: string): any | null {
            const value = this.get(name);
            let result;

            try {
                result = JSON.parse(value);
            } catch (err) {
                result = null;
                console.warn(err);
            }

            return result;
        },

        getAll(): Object {
            return cookies.getAll({ doNotParse: true });
        },

        remove(name: string, options?: CookiesOptionsInterface): void {
            cookies.remove(name, options);
        },

        set(name: string, value: string, options?: CookiesOptionsInterface): void {
            cookies.set(name, value, options);
        }
    };
})();
