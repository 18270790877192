import { getDate, getTime } from '../services/server-date-time';

export default class DateFormatter {
    public static MS_IN_MINUTE = 60 * 1000;
    public static MS_IN_HOUR = DateFormatter.MS_IN_MINUTE * 60;
    public static MS_IN_DAY = DateFormatter.MS_IN_HOUR * 24;

    public static MONTHS = [
        'styczeń',
        'luty',
        'marzec',
        'kwiecień',
        'maj',
        'czerwiec',
        'lipiec',
        'sierpień',
        'wrzesień',
        'październik',
        'listopad',
        'grudzień',
    ];

    public static MONTHS_ROMAN = [
        'i',
        'ii',
        'iii',
        'iv',
        'v',
        'vi',
        'vii',
        'viii',
        'ix',
        'x',
        'xi',
        'xii',
    ];

    public static DAYS = [
        'niedziela',
        'poniedziałek',
        'wtorek',
        'środa',
        'czwartek',
        'piątek',
        'sobota',
    ];

    private date: Date;

    constructor(date: Date | number | string) {
        this.date = new Date(date);
    }

    get monthText(): string {
        return DateFormatter.MONTHS[this.date.getMonth()];
    }

    get monthNumber(): number {
        return this.date.getMonth();
    }

    get monthRoman(): string {
        return DateFormatter.MONTHS_ROMAN[this.date.getMonth()];
    }

    get dayText(): string {
        let now = getTime();
        let today = Math.floor(now / DateFormatter.MS_IN_DAY) * DateFormatter.MS_IN_DAY;
        let timestamp = this.date.getTime();

        let dTime = timestamp - today;

        if (dTime >= DateFormatter.MS_IN_DAY * 2 || dTime < 0) {
            return DateFormatter.DAYS[this.date.getDay()];
        } else if (dTime >= DateFormatter.MS_IN_DAY) {
            return 'jutro';
        } else {
            return 'dzisiaj';
        }
    }

    get dayNumber(): number {
        return this.date.getDate();
    }

    get hoursText(): string {
        let h = this.date.getHours();
        return h >= 10 ? `${h}` : `0${h}`;
    }

    get minutesText(): string {
        let m = this.date.getMinutes();
        return m >= 10 ? `${m}` : `0${m}`;
    }
}
