import { DestroyMessageType } from "../actions/message";
import { AppStorageDataType } from "../app-storage-data-type";
import { updateCollection } from "app/helpers/array-helpers";

export default function destroyMessage(state: AppStorageDataType, action: DestroyMessageType) {

    const matches = { id: action.payload.promotedItemId };
    const updates = {
        message: null as string
    };
    const promotedItems = updateCollection(state.promotedItems, matches, updates);

    return {
        ...state,
        promotedItems
    };
}
