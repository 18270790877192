import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    MessageConfigType,
    MiniMediaDefinition,
    PromotedItemType,
} from "../../config-object-type";
import { AppStorageDataType } from "../../storage/app-storage-data-type";
import {
    createPlayer,
    PlayerAPI,
    PlayerClass,
    PlayerConfig,
    PlayerError,
} from "../../services/player/player-service";
import { closePlayer } from "../../storage/actions/close-player";
import { playerError } from "../../storage/actions/player-error";
import { UserInterface } from "../../storage/actions/set-user";
import { destroyMessage, showMessage } from "../../storage/actions/message";
import { showTokenForm } from "../../storage/actions/show-token-form";
import { initPlayer } from "../../storage/actions/init-player";
import {
    navigateToPurchasePage,
    navigateToLoginPage,
} from "../../helpers/ipla-url-helper";
import { goToPurchasePage } from "../../storage/actions/go-to-purchase-page";
import { setAccess } from "../../storage/actions/set-access";
import isFree from "app/storage/helpers/is-free";
import { findTheClosestObject } from "../../helpers/array-helpers";
import { defaultConfig } from "app/default-config";

interface ConnectedState {
    user?: UserInterface;
}

interface ConnectedDispatch {
    playerError(promotedMediaId: string, error: PlayerError): void;
    closePlayer(promotedMediaId: string): void;
    showMessage(id: string, messageConfig: MessageConfigType): void;
    setAccess(value: boolean): void;
}

interface OwnProps {
    media: PromotedItemType;
}

interface OwnState {}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class PlayerComponent extends React.Component<ComponentProps, OwnState> {
    private containerPlayer: any = React.createRef();
    private player: PlayerAPI;
    private playerClass: PlayerClass;

    public async componentDidMount(): Promise<void> {
        const { user, media } = this.props;

        if (!user && !isFree(media)) {
            navigateToPurchasePage(media);
            return;
        }

        this.playerClass = await createPlayer();
        this.player = new this.playerClass();

        try {
            await this.setupPlayer();
            const gmID: MiniMediaDefinition = {
                cpid: media.gmID.cpid,
                id: media.gmID.id,
            };
            await this.loadMedia(gmID);
        } catch (e) {
            this.onPlayerError(e);
        }
    }

    private async loadMedia(gmID: MiniMediaDefinition): Promise<void> {
        await this.player.loadMedia(gmID);
    }

    private async setupPlayer(): Promise<void> {
        const { user, media } = this.props;
        const playerConfig: PlayerConfig = {
            container: this.containerPlayer.current,
            load: {
                preload: true,
                autoplay: true,
            },
            stats: {
                domain: "live.polsatboxgo.pl",
            },
            userAgentData: {
                portal: 'pbg',
            },
            options: {
                forceAdaptiveStreaming: false,
                chromecastAppID: defaultConfig.chromecastAppID,
            },
        };
        const poster = findTheClosestObject(
            media.posters,
            ["size", "width"],
            window.innerWidth
        );

        if (user) {
            playerConfig.user = {
                id: user.userId,
                session: user.session,
            };
        }

        this.player.config(playerConfig);
        await this.player.registerPrePlayDataPlugin(media.gmID.id, poster.src);
        await this.player.onError(this.onPlayerError.bind(this));
    }

    private onPlayerError(error: PlayerError) {
        const { closePlayer, playerError, showMessage, media, setAccess } =
            this.props;
        const errorType = this.playerClass.getErrorTypes();

        const isNotBrowserSupport = error.details.some(
            (item) => (item || {}).isNotBrowserSupport
        );

        switch (error.type) {
            case errorType.DRM_PAYMENT_REQUIRED:
                // Czyścimy informacje o dostępie do tego materiału, aby userowi pokazały się przyciski kup/token
                setAccess(false);

                closePlayer(media.id);
                playerError(media.id, error);

                let buttons: any[] = [
                    {
                        title: "Spróbuj ponownie",
                        icon: "icon-clock",
                        actions: [initPlayer(media.id)],
                    },
                ];

                if (media.accessByPacks || media.accessByToken) {
                    buttons.push({
                        title: "Kup dostęp",
                        icon: "icon-cart",
                        actions: [goToPurchasePage(media)],
                    });
                }

                showMessage(media.id, {
                    title: "Brak dostępu do materiału",
                    info: "W przypadku problemów skontaktuj się z pomoc@polsatboxgo.pl",
                    buttons,
                });
                break;

            case errorType.MEDIA_NOT_FOUND:
                if (isNotBrowserSupport) {
                    closePlayer(media.id);
                    showMessage(media.id, {
                        icon: "icon-crc-error",
                        title: "Brak materiału na Twojej przeglądarce",
                        info: "Skorzystaj z przeglądarki Chrome, Firefox, Opera lub z aplikacji Polsat Box Go w telefonie z systemem Android/iOS",
                        buttons: [],
                    });
                }
                break;

            default:
                // INFO: Obsługa błędów po stronie playera
                console.error(error);
        }
    }

    public async componentWillUnmount() {
        await this.player.destroy();
    }

    public async componentDidUpdate(prevState: ComponentProps) {
        const { id, cpid } = this.props.media.gmID;
        if (prevState.media.gmID.id === id) {
            return;
        }

        try {
            await this.player.destroy();
            await this.setupPlayer();
            await this.loadMedia({ cpid, id });
        } catch (e) {
            this.onPlayerError(e);
        }
    }

    public render() {
        return (
            <div className="player-wrapper">
                <div
                    className="close-icon"
                    onClick={() => this.props.closePlayer(this.props.media.id)}
                >
                    <i className="icon icon-cancel"></i>
                </div>

                <div ref={this.containerPlayer} className="player"></div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppStorageDataType): ConnectedState => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch,
    props: OwnProps
): ConnectedDispatch => {
    return {
        playerError(promotedMediaId, error) {
            dispatch(playerError(promotedMediaId, error));
        },
        closePlayer(promotedMediaId) {
            dispatch(closePlayer(promotedMediaId));
        },
        showMessage(id, messageConfig: MessageConfigType) {
            dispatch(showMessage(id, messageConfig));
        },
        setAccess(value: boolean) {
            dispatch(setAccess(value, props.media.gmID));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerComponent);
