import isEmpty from 'lodash.isempty';

const notEmpty = (str: any): string | null => {
    if (isEmpty(str)) {
        return 'To pole nie może być puste';
    }
    return null;
};

export default notEmpty;
