import { ACTION_TYPE } from '.';
import { PromotedItemType } from 'app/config-object-type';

export type GoToPurchasePageAction = {
    type: ACTION_TYPE.GO_TO_PURCHASE_PAGE,
    media: PromotedItemType
};

/**
 * Akcja chowająca panel z formularzem do wprowadzenia danych testowych
 */
export function goToPurchasePage(media: PromotedItemType): GoToPurchasePageAction {
    return {
        type: ACTION_TYPE.GO_TO_PURCHASE_PAGE,
        media
    };
}
