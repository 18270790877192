import { PromotedItemType } from '../config-object-type';

const DEFAULT_DOMAIN = 'polsatboxgo.pl';

export function getLoginUrl(media: PromotedItemType): string {
    // Aktualnie wykorzystujemy fakt że przekierowanie na strone płatności
    // jednocześnie wymusi logowanie i sprawdzi dostepność materiału.
    switch (media.gmID.cpid) {
        case 0:
            return `https://${media.domain || DEFAULT_DOMAIN}/uzytkownik/zaloguj/natywnie;next=%2Flive%2Fipla%2F${media.gmID.id}`;

        case 1:
            return `https://${media.domain || DEFAULT_DOMAIN}/uzytkownik/zaloguj/natywnie;next=%2Fwideo%2Fx%2Fx%2F${media.gmID.id}`;

        case 7:
            return `https://${media.domain || DEFAULT_DOMAIN}/uzytkownik/zaloguj/natywnie;next=%2Fpakiety%2F${media.gmID.id}`;
    }
}

export function getPaymentUrl(media: PromotedItemType): string {
    const hostname = media.domain || DEFAULT_DOMAIN;
    const id = media.productId.id;
    const type = media.productId.type;
    const subType = media.productId.subType;
    const returnUrl = encodeURIComponent(getReturnUrl(media));
    return `https://${hostname}/purchase/${id}/${type}/${subType}/step-1?next=${returnUrl}`;
}

export function navigateToLoginPage(media: PromotedItemType) {
    window.location.href = getLoginUrl(media);
}

export function navigateToPurchasePage(media: PromotedItemType) {
    window.location.href = getPaymentUrl(media);
}

function getReturnUrl(media: PromotedItemType) {
    return `https://live.polsatboxgo.pl?id=${media.id}&autoplay=1&buyStatus=$STATUS$`;
}
