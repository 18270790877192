import { ACTION_TYPE } from '.';

export type InitPlayerActionType = {
    type: ACTION_TYPE.INIT_PLAYER,
    payload: {
        promotedItemId: string
    }
};

export function initPlayer(promotedItemId: string): InitPlayerActionType {
    return {
        type: ACTION_TYPE.INIT_PLAYER,
        payload: {
            promotedItemId
        }
    };
}
