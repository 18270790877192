import * as React from 'react';
import { ChangeEvent, MouseEvent } from 'react';

interface Props {
    type: string;
    name: string;
    inputClassName: string;
    value?: string;
    placeholder?: string;
    classWrapper?: string;
    disabled?: boolean;
    checked?: boolean;
    showLabel?: boolean;
    labelValue?: string;
    labelClassName?: string;
    onChange?(evt: ChangeEvent<HTMLInputElement>): void;
    onClick?(evt: MouseEvent<HTMLInputElement>): void;
}

const Input: React.StatelessComponent<Props> = (props) => {
    return (
        <div className={props.classWrapper}>
            {
                props.showLabel &&
                    <label className={props.labelClassName}>
                        {props.labelValue}
                    </label>
            }
            <input
                type={props.type}
                name={props.name}
                className={props.inputClassName}
                placeholder={props.placeholder}
                value={props.value}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                onClick={props.onClick}
            />
        </div>
    );
};

export default Input;
