import * as React from 'react';
import { PromotedItemType } from '../../config-object-type';

import ActionsButtons from '../state-store/actions-buttons';
import Poster from './poster';
import AvailablePlatforms from './available-platforms';
import PromotedItemPriceBox from './promoted-item-price-box';
import PromotedItemDate from '../state-store/promoted-item-date';
import PromotedItemDescriptionBox from './promoted-item-description-box';
import { AppStorageDataType } from 'app/storage/app-storage-data-type';
import hasAccess from 'app/storage/selectors/has-access';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

interface ConnectedState {
    hasAccess: boolean;
}

interface OwnProps {
    item: PromotedItemType;
}

interface ConnectedDispatch { }

interface OwnState { }

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class PromoboxListMetadataComponent extends React.Component<ComponentProps, OwnState> {
    public render() {

        let {item, hasAccess} = this.props;

        return (
            <div className='promobox__metadata'>
                <div className='row'>
                    <div className='col-6 col-lg-4'>
                        <div className='row promobox-about'>
                            <div className='col-12 col-md-5 event-start-date'>
                                {
                                    item.playableStart &&
                                    <PromotedItemDate item={item} />
                                }
                            </div>
                            <div className='col-12 col-md-7'>
                                {
                                    Boolean(item.posters.length) &&
                                    <Poster posters={item.posters} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-lg-8'>
                        <div className='row height-100'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                                <div className='promobox-about'>
                                    <div>
                                        {
                                            item.category &&
                                            <p className='category'>
                                                {item.category}
                                            </p>
                                        }
                                        <h1 className='title'>{item.title}</h1>
                                        {
                                            <div className='promobox-about__more'>
                                                <p className='description'>
                                                    {item.description}
                                                </p>
                                                {
                                                    item.platforms &&
                                                    <AvailablePlatforms
                                                        platforms={item.platforms}
                                                    />
                                                }
                                                <PromotedItemDescriptionBox
                                                    item={item}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12  col-md-12 col-lg-6'>
                                <div className='row promobox-access'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                                        {
                                            item.price &&
                                            !hasAccess &&
                                            !item.limitExceeded &&
                                            <PromotedItemPriceBox price={item.price} />
                                        }
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-7'>
                                        <ActionsButtons
                                            promotedItem={item}
                                            btnSize='small'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
};

const mapStateToProps = (state: AppStorageDataType, props: OwnProps): ConnectedState => {
    return {
        hasAccess: hasAccess(state)(props.item.gmID)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {};
};

const PromoboxListMetadata = connect(mapStateToProps, mapDispatchToProps)(PromoboxListMetadataComponent);
export default PromoboxListMetadata;
