import * as React from "react";
import Stats from "app/services/stats";
import IplaLogo, { LOGO_TYPES } from "./ipla-logo";

interface Props {}

const Header: React.StatelessComponent<Props> = () => (
  <header className="page-header">
    <div className="page-header__logo logo">
      <a href="/" className="logo__link">
        <IplaLogo type={LOGO_TYPES["LIGHT"]} logoAlt="polsatboxgo.pl" />
        <IplaLogo type={LOGO_TYPES["DARK"]} logoAlt="polsatboxgo.pl" />
      </a>
    </div>

    <div className="page-header__actions">
      <a
        className="button"
        href="https://polsatboxgo.pl/start"
        onClick={(event) => {
          event.preventDefault();
          Stats.instance.goToPageClicked().then(() => {
            window.location.href = "https://polsatboxgo.pl/start";
          });
        }}
      >
        Przejdź do strony głównej
        <i className="icon icon-right-small">&nbsp;</i>
      </a>
    </div>
  </header>
);

export default Header;
