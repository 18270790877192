import getItemsList from "./get-items-list";
import memoize from 'lodash.memoize'
import customCreateSelector from "./custom-create-selector";

/**
 * Zwraca element promowany o podanym ID
 */

const getItem = customCreateSelector(
    [getItemsList],
    (items) => {

        return memoize(
            (id) => {
                return items.find(item => item.id === id);
            }
        )
    }
)

export default getItem;
