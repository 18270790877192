import * as React from 'react';

import { PromotedItemType, INFOBOX_TYPE } from '../../config-object-type';
import Player from '../state-store/player';
import TokenForm from '../state-store/token-form-promoted-partial';
import PromoboxMetadata from '../state-less/meta-dat-promoted';
import NotifyBoxPromoted from '../state-less/notify-box-promoted';
import Message from '../state-store/message';
import Infobox from '../state-less/info-box';
import Poster from '../state-less/poster';
import TokenInfo from '../state-store/token-info';

interface OwnProps {
    media: PromotedItemType;
}

interface OwnState {
}

class MainPromotedItemComponent extends React.Component<OwnProps, OwnState> {
    public static defaultProps: OwnProps = {
        media: null
    };

    public render() {
        const { media } = this.props;

        let content: JSX.Element;

        if (media.isPlayerShowed) {
            content = (
                <Player media={media} />
            );
        } else {
            content = (
                <div className='promobox__main'>
                    {
                        Boolean(media.posters.length) &&
                        <Poster posters={media.posters} />
                    }
                    <PromoboxMetadata item={media} />
                    {
                        media.isFormTokenShowed &&
                        <TokenForm promotedItem={media} />
                    }
                    {
                        media.limitExceeded &&
                        <NotifyBoxPromoted
                            header='Dziękujemy za duże zainteresowanie transmisją'
                            message='W celu zapewnienia najwyższej jakości usług osobom, które już zakupiły dostęp, sprzedaż została wstrzymana'
                        />
                    }
                    {
                        media.message &&
                        <Message
                            message={media.message}
                            icon={media.message.icon}
                        />
                    }
                </div>
            )
        }

        let className = `promobox ${media.theme ? `promobox--${media.theme}` : ''}`;

        return (
            <section className={className}>
                { content }
                <TokenInfo
                    promotedItem={media}
                />
                {
                    media.infoBoxType !== INFOBOX_TYPE.NONE &&
                    <Infobox media={media} />
                }
            </section>
        );
    }

}

export default MainPromotedItemComponent;
