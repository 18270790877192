import { Reducer } from 'redux';

import { getInitAppData } from '../init-data';
import { APP_GLOBAL_STATE, AppStorageDataType } from '../app-storage-data-type';
import { ACTION_TYPE, AppActionType } from '../actions';
import { updateCollection } from '../../helpers/array-helpers';
import updateConfigReducer from './update-config';
import { navigateToPurchasePage } from '../../helpers/ipla-url-helper';
import setAccess from './set-access';
import destroyMessageReducer from './destroy-message';
import { destroyMessage as destroyMessageAction } from '../actions/message';

export const reducer: Reducer<AppStorageDataType> = (state: AppStorageDataType = getInitAppData(), action: AppActionType): AppStorageDataType => {
    switch (action.type) {
        case ACTION_TYPE.SHOW_LOADER:
            return {
                ...state,
                showGlobalSpinner: action.payload.isGlobalSpinner
            };

        case ACTION_TYPE.SET_TITLE:
            return {
                ...state,
                pageTitle: action.value
            };

        case ACTION_TYPE.SET_USER:
            return {
                ...state,
                user: action.value
            };

        case ACTION_TYPE.UPDATE_CONFIG: {
            return updateConfigReducer(state, action);
        }

        case ACTION_TYPE.INIT_PLAYER: {
            // Przed inicializacją playera musimy zamknąć wszystkie poprzednie
            const promotedItemsClosedAllPlayer = state.promotedItems.map((item) => {
                return { ...item, isPlayerShowed: false };
            });
            const matches = { id: action.payload.promotedItemId };
            const updates = { isPlayerShowed: true };
            const promotedItems = updateCollection(promotedItemsClosedAllPlayer, matches, updates);

            return {
                ...state,
                promotedItems
            };
        }

        case ACTION_TYPE.CLOSE_PLAYER: {
            const matches = { id: action.payload.promotedItemId };
            const updates = { isPlayerShowed: false };
            const promotedItems = updateCollection(state.promotedItems, matches, updates);

            return {
                ...state,
                promotedItems
            };
        }
        case ACTION_TYPE.TOKEN_STATUS_ACTIVATE: {
            const matches = { id: action.payload.tokenStatusActivate };
            const updates = { accessByToken: true };
            const promotedItems = updateCollection(state.promotedItems, matches, updates);

            return {
                ...state,
                promotedItems
            };
        }
        case ACTION_TYPE.CLOSE_TOKEN_FORM: {
            const matches = { id: action.payload.promotedItemId };
            const updates = { isFormTokenShowed: false };
            const promotedItems = updateCollection(state.promotedItems, matches, updates);

            return {
                ...state,
                promotedItems
            };
        }

        case ACTION_TYPE.SHOW_TOKEN_FORM: {

            // Ponieważ nie mamy możliwości funkcjonalnego wyświetlenia formularza
            // przy wyświetlonym MessageBox - pokazując formularz niszczymy wiadomość
            let destroyMessageState = destroyMessageReducer(state, destroyMessageAction(action.payload.promotedItemId))

            const matches = { id: action.payload.promotedItemId };
            const updates = { isFormTokenShowed: true };
            const promotedItems = updateCollection(destroyMessageState.promotedItems, matches, updates);

            return {
                ...destroyMessageState,
                promotedItems
            };
        }

        case ACTION_TYPE.DESTROY_MESSAGE: {
            return destroyMessageReducer(state, action);
        }

        case ACTION_TYPE.SHOW_MESSAGE: {
            const matches = { id: action.payload.promotedItemId };
            const updates = {
                message: action.payload.message
            };
            const promotedItems = updateCollection(state.promotedItems, matches, updates);

            return {
                ...state,
                promotedItems
            };
        }

        case ACTION_TYPE.ENABLE_DEBUG_MODE: {
            return {
                ...state,
                debugMode: true
            };
        }

        case ACTION_TYPE.DISABLE_DEBUG_MODE: {
            return {
                ...state,
                debugMode: false
            };
        }

        case ACTION_TYPE.SHOW_TESTING_PANEL: {
            return {
                ...state,
                isTestingPanelVisible: true
            };
        }

        case ACTION_TYPE.HIDE_TESTING_PANEL: {
            return {
                ...state,
                isTestingPanelVisible: false
            };
        }

        case ACTION_TYPE.SET_DEBUG_SETTINGS: {
            return {
                ...state,
                debugSettings: action.settings
            };
        }

        case ACTION_TYPE.SET_NOW:
            return {
                ...state,
                now: action.value
            }

        case ACTION_TYPE.SET_ACTIVE_PROMOTED_ITEM: {
            return {
                ...state,
                activePromotedItemID: action.activePromotedItem
            };
        }

        case ACTION_TYPE.GO_TO_PURCHASE_PAGE: {
            navigateToPurchasePage(action.media)
            return state;
        }

        case ACTION_TYPE.SET_ACCESS: {
            return setAccess(state, action);
        }

        default:
            return state;
    }
};
