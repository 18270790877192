import AppStorage from '../../storage';
import { setNow } from 'app/storage/actions/set-now';

/**
 * Różnica czasu server - środowisko usera
 */
let deltaTime: number;

const getDeltaTime = (): number => {
    return deltaTime || 0;
};

const getServerTimeNow = (): number => {
    const localDateNow = new Date().getTime();
    return localDateNow - getDeltaTime();
};

const isDebugMode = (): boolean => {
    try {
        const store = AppStorage.instance.getState();
        const debugMode = store.debugMode;
        return debugMode;
    } catch (e) {
        return false;
    }
};

const getDebugModeTime = (): number => {
    const store = AppStorage.instance.getState();
    if (store.debugSettings.time) {
        const debugModeTime = new Date(store.debugSettings.time).getTime() || 0;
        return debugModeTime;
    } else {
        return getServerTimeNow();
    }
};

export const getTime = (): number => {
    if (isDebugMode()) {
        return getDebugModeTime();
    }
    return getServerTimeNow();
};

export const getDate = (): Date => {
    return new Date(getTime());
};

export const setServerTime = (serverTime: number | Date) => {
    if (serverTime instanceof Date) {
        serverTime = serverTime.getTime();
    }
    const dateNow = new Date().getTime();
    deltaTime = dateNow - serverTime;

    AppStorage.instance.dispatch(setNow(getTime()))
};
