import * as React from "react";
import classNames from "classnames";

const Logo = require("../../../../assets/images/logo.png");
export enum LOGO_TYPES {
  DARK = "dark",
  LIGHT = "light",
}

interface Props {
  logoAlt: string;
  type: LOGO_TYPES;
}

class IplaLogo extends React.Component<Props> {
  private darkLogo = Logo;
  private lightLogo = Logo;

  constructor(props: Props) {
    super(props);
  }

  private getLogo() {
    return this[`${this.props.type}Logo`];
  }

  public render() {
    const { logoAlt, type } = { ...this.props };

    const classLogo = classNames({
      ["logo__image"]: true,
      [`logo-${type}`]: true,
    });

    return <img alt={logoAlt} className={classLogo} src={this.getLogo()} />;
  }
}

export default IplaLogo;
