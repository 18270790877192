import React, { StatelessComponent } from 'react';
import { connect } from 'react-redux';
import DateFormatter from 'app/helpers/date-formatter';
import { PromotedItemType } from 'app/config-object-type';
import { AppStorageDataType } from 'app/storage/app-storage-data-type';
import isPlayable from 'app/storage/selectors/is-playable';
import getEventStartTime from 'app/storage/selectors/get-event-start-time';

interface ConnectedState {
    isPlayable: boolean;
    getEventStartTime: string;
}

interface Props {
    item: PromotedItemType;
}

type ComponentProps = ConnectedState & Props;

const PromotedItemDateComponent: StatelessComponent<ComponentProps> = ({ item, isPlayable, getEventStartTime }) => {
    const {
        dayText,
        dayNumber,
        monthRoman,
        minutesText,
        hoursText } = new DateFormatter(getEventStartTime);
    const isTransmitedNow = isPlayable;

    return (
        <ul className='event-start-date__list'>
            {
                isTransmitedNow &&
                <li className='event-start-date__list__item'>
                    <span className='live text-uppercase'>trwa</span>
                </li>
            }
            {
                !isTransmitedNow &&
                <li className='event-start-date__list__item'>
                    <span className='weekday'>{dayText}</span>&nbsp;
                                                <span className='date-day'>{dayNumber}</span>&nbsp;
                                                <span className='date-month'>{monthRoman}</span>
                </li>
            }
            <li className='event-start-date__list__item'>
                <span className='event-start-date__prefix'>od</span>&nbsp;&nbsp;
                                        <span className='time'>{hoursText}:{minutesText}</span>
            </li>
        </ul>
    );
};

const mapStateToProps = (state: AppStorageDataType, props: Props): ConnectedState => {
    return {
        isPlayable: isPlayable(state)(props.item.id),
        getEventStartTime: getEventStartTime(state)(props.item.id)
    };
};

const PromotedItemDate = connect(mapStateToProps)(PromotedItemDateComponent);

export default PromotedItemDate;
