import { ACTION_TYPE } from '.';

export type SetTokenStatusActivateType = {
    type: ACTION_TYPE.TOKEN_STATUS_ACTIVATE,
    payload: {
        tokenStatusActivate: string|number
    }
};

export function setTokenStatusActivate(id: string | number): SetTokenStatusActivateType {

    return {
        type: ACTION_TYPE.TOKEN_STATUS_ACTIVATE,
        payload: {
            tokenStatusActivate: id
        }
    };
}
