import { render } from 'app';
import '../assets/styles/main.scss';
import Stats from 'app/services/stats';

require('es6-shim');

window.addEventListener('load', () => {
    Stats.injectGTM();
    Stats.instance.initPageStartWatcher();
    const root = document.getElementById('root');
    render(root);
});
