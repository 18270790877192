import React, { Component, StatelessComponent } from 'react';

interface Props {
    price: string;
}

const PromotedItemPriceBox: StatelessComponent<Props> = ({ price }) => {
    return (
        <div className='price-box'>
            <span className='price-box__text'>
                Cena&nbsp;od
            </span>
            <span className='price-box__value'>
                {price}
            </span>
            <span className='price-box__currency'>
                PLN
            </span>
        </div>
    );
};

export default PromotedItemPriceBox;
