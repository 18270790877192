import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import WindowResizeService from '../../services/window-resize';
import { findTheClosestObject } from '../../helpers/array-helpers';

interface PosterI {
    size: {
        height: number;
        width: number;
    };
    src: string;
}

interface Props {
    posters: Array<PosterI>;
}

interface OwnState {
    contenerWidth: number;
    poster: PosterI;
}

class Poster extends Component<Props, OwnState> {

    private node: any;
    private windowResizer = WindowResizeService.createListener();

    public constructor(props: Props) {
        super(props);

        const poster = findTheClosestObject(
            this.props.posters,
            ['size', 'width'],
            window.innerWidth
        );

        this.state = {
            contenerWidth: 0,
            poster
        };

    }

    private getSizeContener = (event: any) => {
        if (!event) return;

        const item = findDOMNode(event);

        return {
            contenerWidth: item['offsetWidth']
        };
    };

    private updateSize = () => {
        this.setState({
            contenerWidth: this.getSizeContener(this.node).contenerWidth,
        });
    };

    private async updatePoster({posters} = {posters: this.props.posters}) {
        // Użyty poniżej await jest potrzebny do wybrania prawidłowego postera
        await this.updateSize();

        const poster = findTheClosestObject(
            posters,
            ['size', 'width'],
            this.state.contenerWidth
        );

        this.setState({
            poster
        });
    }

    public componentDidMount() {
        this.updatePoster();
        this.windowResizer.onResize((payload) => {
            this.updatePoster();
        });
    }

    public componentWillUnmount() {
        this.windowResizer.removeAllListeners();
    }

    public componentWillReceiveProps(nextProps: Props) {
        this.updatePoster(nextProps);
    }

    public render() {
        const styles = {
            backgroundImage: `url(${this.state.poster.src})`
        };
        return (
            <div
                className='promobox__poster'
                ref={event => (this.node = event)}
                style={styles}
            >
            </div>
        );
    }
}

export default Poster;
