import React, { Component, StatelessComponent } from 'react';
import { PromotedItemType } from '../../config-object-type';

interface Props {
    item: PromotedItemType;
}

const PromotedItemDescriptionBox: StatelessComponent<Props> = ({ item }) => {

    const availablePacks =
        item.accessByPacks &&
        item.accessByPacks
            .map((item, key) => {
                return (
                    <li key={key}>
                        <a href={item.url} target='_blank'>
                            {item.name}
                        </a>
                    </li>
                );
            })

    return (
        <div className='description'>
            {
                item.geoLocationInfo &&
                <p>
                    {item.geoLocationInfo}
                    <br />
                </p>
            }
            <p className='list list--inline list--decoration-none description__packs'>
                <span>Materiał jest dostępny</span>
                {
                    availablePacks &&
                    item.accessByPacks.length === 1 &&
                    <span>&nbsp;w pakiecie </span>
                }
                {
                    availablePacks &&
                    item.accessByPacks.length > 1 &&
                    <span>&nbsp;w pakietach: </span>
                }
                {availablePacks}
                {
                    availablePacks &&
                    item.accessByToken &&
                    <span>&nbsp;lub</span>
                }
                {
                    item.accessByToken &&
                    <span>&nbsp;po zakupie kodu jednorazowego.</span>
                }
            </p>

        </div>
    );
};

export default PromotedItemDescriptionBox;
