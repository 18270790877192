import { ConfigObjectType } from './config-object-type';

/**
 * Domyslne wartości konfiguracji strony
 *
 */
export const defaultConfig: ConfigObjectType = {
    promotedItems: [],
    showMultipleItems: true,
    loginURL: `http://www.ipla.tv/uzytkownik/zaloguj/natywnie?return_url=http://live.ipla.tv`,
    autoRedirectToPaymentPage: true,
    showItemListOnPlaybackEnd: false,
    chromecastAppID: 'E3FC9898',
};
