import * as React from 'react';
import { MouseEvent } from 'react';

type Props = {
    onClick(evt: MouseEvent<HTMLButtonElement>): void;
    title: string;
    icon: string;
};

const ButtonComponent: React.StatelessComponent<Props> = ({ onClick, title, icon = 'icon-grid-square' }) => (
    <button
        className='button'
        onClick={onClick}
    >
        <i className={'icon ' + icon}>&nbsp;</i>
        {title}
    </button>
);

export default ButtonComponent;
