import * as React from 'react';
import PromotedItem from './promoted-item';
import { PromotedItemType } from '../../config-object-type';
import getItemsListToShow from '../../storage/selectors/get-item-list-to-show';
import NoItemsInfo from '../state-less/no-items-info';
import NotSupportInfo from '../state-less/not-support-info';

interface OwnProps {
    promotedItemsList: PromotedItemType[];
}

type ComponentProps = OwnProps;
type OwnState = {};

export default class PromotedItemsListComponent extends React.Component<ComponentProps, OwnState> {
    public render() {
        let items = this.props.promotedItemsList.map((item, key) => {
            return <PromotedItem key={key} media={item} />;
        });

        // Internet Explorer 6-11
        const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

        const content = (items.length) ? items :
            (isIE ? <NotSupportInfo /> : <NoItemsInfo />);

        return (
            <section>
                {content}
            </section>
        );
    }
}
