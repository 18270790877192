import getActiveExtraPriorities from "./get-active-extra-priorities";
import memoize from 'lodash.memoize'
import customCreateSelector from "./custom-create-selector";

const getExtraPriority = customCreateSelector(
    [
        getActiveExtraPriorities
    ],
    (prioritiesList) => {
        return memoize(
            (id: string) => {
                let item = prioritiesList.find(p => {
                    return p.itemId === id
                })
                return item ? (item.priority || 0) : 0;
            }
        )
    }
)

export default getExtraPriority;
