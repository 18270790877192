import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppStorageDataType } from "app/storage/app-storage-data-type";
import { showTokenForm } from "app/storage/actions/show-token-form";
import { PromotedItemType } from "app/config-object-type";
import hasAccess from "app/storage/selectors/has-access";
import isFree from "app/storage/selectors/is-free";

interface ConnectedState {
    hasAccess: boolean;
    isFree: boolean;
}

function mapStateToProps(
    state: AppStorageDataType,
    props: OwnProps
): ConnectedState {
    return {
        hasAccess: hasAccess(state)(props.promotedItem.gmID),
        isFree: isFree(state)(props.promotedItem.id),
    };
}

interface ConnectedDispatch {
    openTokenForm(promotedItemId: string): void;
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        openTokenForm(id: string): void {
            dispatch(showTokenForm(id));
        },
    };
};

interface OwnProps {
    promotedItem: PromotedItemType;
}

interface OwnState {}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class TokenInfoComponent extends React.Component<ComponentProps, OwnState> {
    // Inicjalizacja stanu komponentu.
    public readonly state: OwnState = {
        newTitle: "none",
    };

    // Funkcja renderująca komponent.
    public render() {
        const { promotedItem, openTokenForm, isFree, hasAccess } = this.props;

        const showMe =
            promotedItem.accessByToken &&
            !promotedItem.isPlayerShowed &&
            !hasAccess &&
            !isFree;

        if (!showMe) {
            return '';
        }

        const mamkodUrl = 'https://polsatboxgo.pl/panel/mamkod';

        return (
            <div className={"token-info"}>
                <i className="icon icon-footer-help token-info__info-icon"></i>
                <p className={`token-info__text`}>
                    Jeśli posiadasz kod aktywacyjny do wydarzenia, przejdź do strony
                    głównej, zaloguj się lub załóż konto i skorzystaj z sekcji <a href={mamkodUrl}>Mam kod</a>.
                </p>
            </div>
        );
    }
}

const TokenInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(TokenInfoComponent);

export default TokenInfo;
