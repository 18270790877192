import { ACTION_TYPE } from '.';
import { PlayerError } from '../../services/player/player-service';

export type PlayerErrorActionType = {
    type: ACTION_TYPE.PLAYER_ERROR,
    payload: {
        promotedMediaId: string,
        error: PlayerError
    }
};

export function playerError(promotedMediaId: string, error: PlayerError): PlayerErrorActionType {
    return {
        type: ACTION_TYPE.PLAYER_ERROR,
        payload: {
            error,
            promotedMediaId
        }
    };
}
