import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { PromotedItemType } from '../../config-object-type';
import { AppStorageDataType } from '../../storage/app-storage-data-type';
import PromoboxListMetadata from '../state-less/meta-data-promoted-list';
import getActiveItem from '../../storage/selectors/get-active-item';
import getNow from '../../storage/selectors/get-now';

interface OwnProps {
    media: PromotedItemType;
}

interface OwnState {
    moreInfoActive: boolean;
}

interface ConnectedState {
    activePromotedItem: PromotedItemType;
    now: number;
    showAllData: boolean;
    isDebugMode: boolean;
}

type ComponentProps = OwnProps & ConnectedState;

class PromotedItemComponent extends Component<ComponentProps, OwnState> {
    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            moreInfoActive: false
        };
    }

    public static defaultProps: OwnProps = {
        media: null
    };

    public render() {

        const { activePromotedItem, media, now, showAllData, isDebugMode } = this.props;
        const isActivePromotedItem = (activePromotedItem && activePromotedItem.id === media.id) || false;

        const className = classNames({
            'promobox promobox-list-item': true,
            [`promobox--${media.theme}`]: media.theme,
            visible: this.state.moreInfoActive && !isActivePromotedItem,
            hide: isActivePromotedItem
        });
        const dataVisible =
            (new Date(media.promoteStart)).getTime() < now &&
            (new Date(media.promoteEnd)).getTime() > now;

        const dataClassName = classNames({
            'full-data-info-table': true,
            'full-data-info-table--bg-green': dataVisible,
            'full-data-info-table--bg-red': !dataVisible
        });

        return (
            <article
                className={className}
                // Wyłączamy rozwijany widok (https://jira.polsatc/browse/JSN-5177)
                // onClick={() => {
                //     this.setState({
                //         moreInfoActive: !this.state.moreInfoActive
                //     });
                // }}
            >
                <PromoboxListMetadata item={media} />

                {showAllData && isDebugMode && (
                    <div className={dataClassName}>
                        <div className="full-data-info-table__element">
                            <div className="full-data-info-table__title">playableStart</div>
                            <div className="full-data-info-table__text">{new Date(media.playableStart).toLocaleString()}</div>
                        </div>
                        <div className="full-data-info-table__element">
                            <div className="full-data-info-table__title">playableEnd</div>
                            <div className="full-data-info-table__text">{new Date(media.playableEnd).toLocaleString()}</div>
                        </div>
                        <div className="full-data-info-table__element">
                            <div className="full-data-info-table__title">promoteStart</div>
                            <div className="full-data-info-table__text">{new Date(media.promoteStart).toLocaleString()}</div>
                        </div>
                        <div className="full-data-info-table__element">
                            <div className="full-data-info-table__title">promoteEnd</div>
                            <div className="full-data-info-table__text">{new Date(media.promoteEnd).toLocaleString()}</div>
                        </div>
                        <div className="full-data-info-table__element">
                            <div className="full-data-info-table__title">visible</div>
                            <div className="full-data-info-table__text">{dataVisible ? 'yes' : 'no'}</div>
                        </div>
                    </div>
                )}
            </article>
        );
    }

}

const mapStateToProps = (state: AppStorageDataType): ConnectedState => {
    return {
        now: getNow(state),
        activePromotedItem: getActiveItem(state),
        showAllData: state.debugSettings.showAllData,
        isDebugMode: state.debugMode
    };
};

const PromotedItem = connect(mapStateToProps)(PromotedItemComponent);

export default PromotedItem;
