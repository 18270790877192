import { ACTION_TYPE } from '.';

export type HideTestingPanelActionType = {
    type: ACTION_TYPE.HIDE_TESTING_PANEL
};

/**
 * Akcja chowająca panel z formularzem do wprowadzenia danych testowych
 */
export function hideTestingPanel(): HideTestingPanelActionType {
    return {
        type: ACTION_TYPE.HIDE_TESTING_PANEL
    };
}
