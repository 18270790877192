import { createSelectorCreator, defaultMemoize } from "reselect";
import { isEqual } from "lodash";

/**
 * Kreator selectora z głebokim sprawdzaniem zmian w obiektach wejściowych
 */
const customCreateSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

export default customCreateSelector;
