import { ACTION_TYPE } from '.';

export type DisableDebugModeAction = {
    type: ACTION_TYPE.DISABLE_DEBUG_MODE
};

/**
 * Akcja wyłączająca tryb testowy
 */
export function disableDebugMode(): DisableDebugModeAction {
    return {
        type: ACTION_TYPE.DISABLE_DEBUG_MODE
    };
}
