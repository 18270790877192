import { AppStorageDataType } from "app/storage/app-storage-data-type";
import { Store } from "redux";

const ACCESS_CACHE_KEY = 'lp-access-cache';

export default class AccessCache {
    static getAccessCache() {
        try {
            return JSON.parse(window.localStorage.getItem(ACCESS_CACHE_KEY)) || {};
        } catch (e) {
            return {};
        }
    }

    constructor(store: Store<AppStorageDataType>) {

        // Przy starcie modułu zapisujemy stan trybu testowego do localStorage
        AccessCache.updateAccessData(store.getState());

        // Przy kazdej zmianie store zapisujemy stan trybu testowego do localStorage
        store.subscribe(() => {
            AccessCache.updateAccessData(store.getState());
        });
    }

    private static updateAccessData(state: AppStorageDataType) {
        try {
            window.localStorage.setItem(ACCESS_CACHE_KEY, JSON.stringify(state.accessCache));
        } catch (e) {}
    }
}
