import memoize from 'lodash.memoize';
import getItem from './get-item';
import getItemsList from './get-items-list';
import customCreateSelector from 'app/storage/selectors/custom-create-selector';
import isFreeHelper from '../helpers/is-free';

/**
 * Zwraca funkcję sprawdzającą czy element o podanym ID jest darmowy
 */
const isFree = customCreateSelector(
    [
        getItemsList
    ],
    (promotedItems) => {
        return memoize(
            (id: string) => {
                // Ponieważ w czasie definicji tego selectora nie wiemy jekiego ID będziemy szukać
                // Jako dependency selektora ustawiamy całą listę i dopiero tutaj korzystamy
                // z selectora `getItem`.
                let item = getItem({
                    promotedItems
                })(id);

                // Jeżęli nie ma informacji o dostępie przez token i pakiet uznajemy że materaił jest darmowy
                return isFreeHelper(item);
            }
        );
    }
);

export default isFree;
