import { PromotedItemType, ExtraPriorities } from '../config-object-type';
import { UserInterface } from './actions/set-user';

export type AppStorageDataType = {
    /**
     * Tytuł strony wyswietlany na zakładce przeglądarki.
     */
    pageTitle: string,

    /**
     * Timestamp czasu który aplikacja uważa za aktualny.
     */
    now: number,

    /**
     * Aktualny stan aplikacji
     */
    appState: APP_GLOBAL_STATE,

    /**
     * Aktywny element promowany
     */
    activePromotedItemID?: string,

    /**
     * Lista elementów aktualnie promowanych na stronie.
     */
    promotedItems: PromotedItemType[],

    /**
     * Czy pokazujemy globalny spinner
     */
    showGlobalSpinner: boolean,
    /**
     * Czy debug mode
     */
    debugMode: boolean,

    /**
     * Dane zalogowanego usera
     */
    user?: UserInterface,

    isTestingPanelVisible: boolean,

    /**
     * Ustawienia trybu testowego
     */
    debugSettings: Partial<DebugSettings>,

    /**
     * Obiekt zawierający parametry GET strony.
     * Jest kasowany w czasie pierwszego wywołania akcji UPDATE_CONFIG
     */
    queryParams?: Partial<QueryParams>,

    /**
     * Baza informacji nt. dostępów do konkretnych materiałów w GetMedii
     */
    accessCache: AccessCachType,

    extraPriorities: Array<ExtraPriorities>
};

export enum APP_GLOBAL_STATE {
    INIT_APP,
    ITEMS_LIST,
}

/**
 * Obiekt opisujący ustawienia trybu testowego
 */
export type DebugSettings = {
    time: string,
    day: string,
    mediaId: string
    isGMMediaSource: boolean,
    isAutoplay: boolean,
    isLimitExceeded: boolean,
    showAllData: boolean
};

export type QueryParams = {
    /**
     * ID elementu który powinien być aktywny na starcie strony
     */
    id: string,

    /**
     * Czy przy starcie trony powino się uruchomić playera z aktywnym materiałem.
     * Jeżeli zostanie podany
     */
    autoplay: boolean,

    /**
     * Czy user posiada dostep do elementu o podanym ID
     */
    hasAccess: boolean
};

export type AccessCachType = {
    // userId - identyfikator usera przychodzący w cookie `ipla-user-recognition`
    [userId: string]: {
        // gmIDStringify - identyfikator materiału w GetMEdia w formacie `${cpid}_${id}`
        [gmIDStringify: string]: boolean;
    }
};
