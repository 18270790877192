import * as React from 'react';
import { Dispatch } from 'redux';

import { connect } from 'react-redux';
import { showTestingPanel } from '../../storage/actions/show-testing-panel';
import { hideTestingPanel } from '../../storage/actions/hide-testing-panel';
import { AppStorageDataType } from '../../storage/app-storage-data-type';
import TestingModePanel from './testing-mode-panel';
import { disableDebugMode } from 'app/storage/actions/disable-debug-mode';

// INFO(ameemarks): propsy komponentu, które reaguja na zmiany ze store'a
interface ConnectedState {
    debugMode: boolean;
    isTestingPanelVisible: boolean;
}

// INFO(ameemarks): propsy komponentu, które uruchamiają akcje w storze
interface ConnectedDispatch {
    showTestingPanel(): void;
    hideTestingPanel(): void;
    disableDebugMode(): void;
}

// INFO(ameemarks): parametry przekazane w osadzeniu
interface OwnProps {
}

// INFO(ameemarks): wewnętrzny stan komponentu, o którym nie potrzebują wiedzieć inne komponenty
interface OwnState {
}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class TestingMode extends React.Component<ComponentProps, OwnState> {
    constructor( props: ComponentProps ) {
        super( props );
        this.state = {
        };
    }

    private showTestingPanel = (): void => {
        this.props.showTestingPanel();
    };

    private hideTestingPanel = (): void => {
        this.props.hideTestingPanel();
    };

    public render() {
        return this.props.debugMode && (
            <React.Fragment>
                <div className='test-mode-info'>
            <span className='test-mode-info__title'>
                Achtung! Das ist &#8680;
            </span>
                    <img
                        className='test-mode-info__gif'
                        src='https://i.giphy.com/media/gw3IWyGkC0rsazTi/200w_d.gif'
                        alt='TEST'
                    />
                    <button
                        className='test-mode-info__button'
                        onClick={() => this.showTestingPanel()}
                    >
                        Otwórz panel testowy
                    </button>
                    <button
                        className='test-mode-info__button'
                        onClick={() => this.props.disableDebugMode()}
                    >
                        Wyłącz tryb testowy
                    </button>
                </div>
                <TestingModePanel
                    hideTestingPanel={this.hideTestingPanel}
                />
            </React.Fragment>
        );
    }
}

// pobierz propsa dla komponentu z wartości store'a
const mapStateToProps = ( state: AppStorageDataType ): ConnectedState => {
    return {
        debugMode: state.debugMode,
        isTestingPanelVisible: state.isTestingPanelVisible
    };
};

// wyślij wartość z komponentu do store'a
const mapDispatchToProps = ( dispatch: Dispatch ): ConnectedDispatch => {
    return {
        showTestingPanel() {
            dispatch( showTestingPanel() );
        },
        hideTestingPanel() {
            dispatch( hideTestingPanel() );
        },
        disableDebugMode() {
            dispatch( disableDebugMode() )
        }
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( TestingMode );
