import React, { ChangeEvent, MouseEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ReactPicker from 'react-datepicker';
import moment, { Moment } from 'moment';

import { setDebugSettings } from '../../storage/actions/set-debug-settings';
import { AppStorageDataType, DebugSettings } from '../../storage/app-storage-data-type';
import Input from '../state-less/input';
import Select from '../state-less/select';

// INFO(ameemarks): propsy komponentu, które reaguja na zmiany ze store'a
interface ConnectedState {
    debugMode: boolean;
    debugSettings: Partial<DebugSettings>;
    isTestingPanelVisible: boolean;
}

// INFO(ameemarks): propsy komponentu, które uruchamiają akcje w storze
interface ConnectedDispatch {
    setDebugSettings(debugConfig: Partial<DebugSettings>): void;
}

// INFO(ameemarks): parametry przekazane w osadzeniu
interface OwnProps {
    hideTestingPanel(): void;
}

export type InputValues = {
    day: any,
    time: string,
    mediaId: string,
    isAutoplay: string,
    isGMMediaSource: string,
    isLimitExceeded: string,
    showAllData: boolean
};

// INFO(ameemarks): wewnętrzny stan komponentu, o którym nie potrzebują wiedzieć inne komponenty
interface OwnState {
    inputValue: Partial<InputValues>;
}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

interface CustomDatePickerInputProps {
    value: string;
    onClick(evt: MouseEvent<HTMLInputElement>): void;
    onChange(evt: ChangeEvent<HTMLInputElement>): void;
    placeholder: string;
}

class CustomDatePickerInput extends React.Component<Partial<CustomDatePickerInputProps>> {
    public render() {
        return (
            <Input
                type='text'
                name='day'
                classWrapper='testing-form__input input-wrapper'
                inputClassName='input-wrapper__input-box'
                placeholder={this.props.placeholder}
                showLabel={true}
                labelValue='Data'
                labelClassName='input-wrapper__input-label'
                value={this.props.value}
                onClick={this.props.onClick}
                onChange={this.props.onChange}
            />
        );
    }
}

class TestingModePanel extends React.Component<ComponentProps, OwnState> {
    constructor(props: ComponentProps) {
        super(props);
        const inputValue = { ...this.props.debugSettings };
        this.state = {
            inputValue: {
                day: moment(inputValue.day),
                time: inputValue.time,
                mediaId: inputValue.mediaId,
                isAutoplay: boolToString(inputValue.isAutoplay),
                isGMMediaSource: boolToString(inputValue.isGMMediaSource),
                isLimitExceeded: boolToString(inputValue.isLimitExceeded),
                showAllData: inputValue.showAllData
            }
        };
    }

    public onSubmitTestingData = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const debugSettingsConfig: Partial<DebugSettings> = this.createDebugSettingsConfig(this.state.inputValue);

        this.props.setDebugSettings(debugSettingsConfig);
        this.props.hideTestingPanel();
    };

    private createDebugSettingsConfig = (data: Partial<InputValues>): Partial<DebugSettings> => {
        const debugSettingsConfig = {};

        Object.keys(data).forEach(key => {
            let value = data[key];

            if (value) {
                switch (key) {
                    case 'isAutoplay':
                    case 'isGMMediaSource':
                    case 'isLimitExceeded':
                        debugSettingsConfig[key] = stringToBool(value);
                        break;

                    default:
                        debugSettingsConfig[key] = value;
                }
            }
        });

        return debugSettingsConfig;
    };

    private handleDatePickerChange = (date: Moment) => {
        const inputValue = {
            ...this.state.inputValue,
            day: date
        };
        this.setState({ inputValue });
    };

    private readonly handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let inputValue = this.state.inputValue;
        const name = event.target.name;
        const value = event.target.value;

        inputValue = { ...inputValue, [name]: value };
        this.setState({ inputValue });
    };

    private readonly handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = this.state.inputValue;
        const name = event.target.name;
        const checked = event.target.checked;

        inputValue = { ...inputValue, [name]: checked };
        this.setState({ inputValue });
    };

    private readonly handleReset = () => {
        let inputValue: InputValues = {
            day: moment(),
            time: '',
            mediaId: '',
            isAutoplay: '',
            isGMMediaSource: '',
            isLimitExceeded: '',
            showAllData: false
        };
        this.setState({ inputValue });
    };

    public render() {
        return (this.props.isTestingPanelVisible && (
                <div className='testing-panel overlay'>
                    <div
                        className='overlay__close testing-panel__close close-icon'
                        onClick={() => this.props.hideTestingPanel()}
                    >
                        <i className='icon icon-cancel'></i>
                    </div>

                    <div className='testing-panel__form-container form-wrapper'>
                        <h2 className='form-wrapper__header'>
                            Panel trybu testowego
                        </h2>
                        <span className='form-wrapper__instruction'>
                    Wybierz ustawienia, które chcesz przetestować:
                </span>
                        <form
                            className='form-wrapper__form testing-form'
                            onSubmit={this.onSubmitTestingData}
                        >
                            <ReactPicker
                                customInput={<CustomDatePickerInput />}
                                selected={this.state.inputValue.day}
                                locale={'pl-PL'}
                                dateFormat='DD/MM/YYYY'
                                placeholderText={'30/05/2018'}
                                withPortal={true}
                                onChange={this.handleDatePickerChange}
                            />
                            <Input
                                type='time'
                                name='time'
                                classWrapper='testing-form__input input-wrapper'
                                inputClassName='input-wrapper__input-box'
                                placeholder='15:45'
                                showLabel={true}
                                labelValue='Godzina'
                                labelClassName='input-wrapper__input-label'
                                value={this.state.inputValue.time}
                                onChange={this.handleInputChange}
                            />
                            <Input
                                type='text'
                                name='mediaId'
                                classWrapper='testing-form__input input-wrapper'
                                inputClassName='input-wrapper__input-box'
                                placeholder='12654'
                                showLabel={true}
                                labelValue='ID materiału'
                                labelClassName='input-wrapper__input-label'
                                value={this.state.inputValue.mediaId}
                                onChange={this.handleInputChange}
                            />
                            <Select
                                name='isAutoplay'
                                selectClassName='input-wrapper__input-box'
                                classWrapper='testing-form__input input-wrapper'
                                showLabel={true}
                                labelValue='Autoplay'
                                labelClassName='input-wrapper__input-label'
                                defaultValue={this.state.inputValue.isAutoplay}
                                options={[
                                    {
                                        optionClassName: 'class',
                                        optionValue: '',
                                        optionLabel: '',
                                    },
                                    {
                                        optionClassName: 'class',
                                        optionValue: 'true',
                                        optionLabel: 'TAK'
                                    },
                                    {
                                        optionClassName: 'class',
                                        optionValue: 'false',
                                        optionLabel: 'NIE'
                                    }
                                ]}
                                onChange={this.handleInputChange}
                            />
                            {
                                __DEVELOPMENT__ &&
                                <Select
                                    name='isGMMediaSource'
                                    labelValue='Żródło materiału playera'
                                    selectClassName='input-wrapper__input-box'
                                    classWrapper='testing-form__input input-wrapper'
                                    showLabel={true}
                                    labelClassName='input-wrapper__input-label'
                                    defaultValue={this.state.inputValue.isGMMediaSource}
                                    options={[
                                        {
                                            optionClassName: 'class',
                                            optionValue: '',
                                            optionLabel: ''
                                        },
                                        {
                                            optionClassName: 'class',
                                            optionValue: 'false',
                                            optionLabel: 'lokalny JSON'
                                        },
                                        {
                                            optionClassName: 'class',
                                            optionValue: 'true',
                                            optionLabel: 'dane z GM'
                                        },
                                    ]}
                                    onChange={this.handleInputChange}
                                />
                            }
                            <Select
                                name='isLimitExceeded'
                                labelValue='Przekroczony limit oglądających'
                                selectClassName='input-wrapper__input-box'
                                classWrapper='testing-form__input input-wrapper'
                                showLabel={true}
                                labelClassName='input-wrapper__input-label'
                                defaultValue={this.state.inputValue.isLimitExceeded}
                                options={[
                                    {
                                        optionClassName: 'class',
                                        optionValue: '',
                                        optionLabel: ''
                                    },
                                    {
                                        optionClassName: 'class',
                                        optionValue: 'true',
                                        optionLabel: 'widok zablokowanego dostępu'
                                    },
                                    {
                                        optionClassName: 'class',
                                        optionValue: 'false',
                                        optionLabel: 'normalny widok'
                                    },
                                ]}
                                onChange={this.handleInputChange}
                            />
                            <Input
                                type='checkbox'
                                name='showAllData'
                                classWrapper='testing-form__input input-wrapper'
                                inputClassName='input-wrapper__input-box'
                                showLabel={true}
                                labelValue='Pokaż wszystkie materiały i wyświetl pomocnicze dane'
                                labelClassName='input-wrapper__input-label'
                                checked={this.state.inputValue.showAllData}
                                onChange={this.handleCheckboxChange}
                            />
                            <Input
                                type='submit'
                                name=''
                                classWrapper='testing-form__submit-wrapper'
                                inputClassName='testing-form__submit submit-button'
                                value='Załaduj ustawienia testowe'
                            />
                            <Input
                                type='reset'
                                name=''
                                classWrapper='testing-form__submit-wrapper'
                                inputClassName='testing-form__submit submit-button submit-button--reset'
                                value='Wyczyść pola formularza'
                                onClick={this.handleReset}
                            />
                        </form>
                    </div>
                </div>
            )
        );
    }

}

// pobierz propsa dla komponentu z wartości store'a
const mapStateToProps = (state: AppStorageDataType): ConnectedState => {
    return {
        debugMode: state.debugMode,
        debugSettings: state.debugSettings,
        isTestingPanelVisible: state.isTestingPanelVisible
    };
};

// wyślij wartość z komponentu do store'a
const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        setDebugSettings(debugConfig) {
            dispatch(setDebugSettings(debugConfig));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestingModePanel);

/**
 * Konwertuje wartość logiczną na stringi 'true' i 'false'
 */
function boolToString(value: boolean): string {
    if (typeof value === 'undefined') {
        return '';
    }

    return value ? 'true' : 'false';
}

/**
 * Konwertuje stringi 'true' i 'false' na wartość logiczną.
 * Dla kazdego innej wartości zwraca `undefined`
 */
function stringToBool(value: string): boolean {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
}
