import { ACTION_TYPE } from '.';

export type ClosePlayerActionType = {
    type: ACTION_TYPE.CLOSE_PLAYER,
    payload: {
        promotedItemId: string
    }
};

export function closePlayer(promotedItemId: string): ClosePlayerActionType {
    return {
        type: ACTION_TYPE.CLOSE_PLAYER,
        payload: {
            promotedItemId
        }
    };
}
