import { ACTION_TYPE } from '.';
import { MiniMediaDefinition } from 'app/config-object-type';

export type SetAccessAction = {
    type: ACTION_TYPE.SET_ACCESS,
    gmID: MiniMediaDefinition,
    value: boolean
};

export function setAccess(value: boolean, gmID: MiniMediaDefinition): SetAccessAction {
    return {
        type: ACTION_TYPE.SET_ACCESS,
        gmID,
        value
    };
}
