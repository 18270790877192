import { ACTION_TYPE } from './index';
import { MessageConfigType } from '../../config-object-type';

export type DestroyMessageType = {
    type: ACTION_TYPE.DESTROY_MESSAGE,
    payload: {
        promotedItemId: string
    }
};

export type ShowMessageType = {
    type: ACTION_TYPE.SHOW_MESSAGE,
    payload: {
        message: MessageConfigType,
        promotedItemId: string;
    }
};

export function destroyMessage(value: string): DestroyMessageType {
    return {
        type: ACTION_TYPE.DESTROY_MESSAGE,
        payload: {
            promotedItemId: value
        }
    };
}

export function showMessage(promotedItemId: string, messageConfig: MessageConfigType): ShowMessageType {
    return {
        type: ACTION_TYPE.SHOW_MESSAGE,
        payload: {
            promotedItemId,
            message: messageConfig
        }
    };
}
