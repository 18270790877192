import React, { Component } from 'react';

interface Prop {
    platforms: Array<string>;
}

const AvailablePlatforms: React.StatelessComponent<Prop> = ({ platforms }) => {
    const platformList = platforms.map((item, key) => {
        return (
            <li key={key}>
                <i className={`icon icon-platforms-${item}`}></i>
            </li>
        );
    });

    return (
        <ul className='list list--inline list--no-decoration available-platforms'>
            {platformList}
        </ul>
    );
};

export default AvailablePlatforms;
