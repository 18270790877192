import { ACTION_TYPE } from '.';

export type EnableDebugModeAction = {
    type: ACTION_TYPE.ENABLE_DEBUG_MODE
};

/**
 * Akcja włączająca tryb testowy
 */
export function enableDebugMode(): EnableDebugModeAction {
    return {
        type: ACTION_TYPE.ENABLE_DEBUG_MODE
    };
}
