import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Input from '../state-less/input';
import Alert from '../state-less/alert';
import Spinner from '../state-less/spinner';
import { closeTokenForm } from '../../storage/actions/close-token-form';
import { setTokenStatusActivate } from '../../storage/actions/set-token-status-activate';
import { hideLoader, showLoader } from '../../storage/actions/show-loader';
import { AppStorageDataType } from '../../storage/app-storage-data-type';
import { MessageConfigType, PromotedItemType } from '../../config-object-type';
import { validator } from '../../services/validate';
import notEmpty from '../../services/validate/types/notEmpty';
import { UserInterface } from '../../storage/actions/set-user';
import { TokenService } from '../../services/token/token-service';
import { HttpService } from '../../services/http/http-service';
import { destroyMessage, showMessage } from '../../storage/actions/message';
import { initPlayer } from '../../storage/actions/init-player';
import { navigateToLoginPage } from '../../helpers/ipla-url-helper';
import Stats from 'app/services/stats';
import isPlayable from 'app/storage/selectors/is-playable';
import { setAccess } from '../../storage/actions/set-access';

interface ConnectedState {
    user?: UserInterface;
    isPlayable: boolean;
}

interface ConnectedDispatch {
    closeTokenForm(id: string): void;
    setTokenStatusActivate(id: string | number): void;
    showLoader(): void;
    hideLoader(): void;
    showMessage(id: string, messageConfig: MessageConfigType): void;
    setAccessToTrue(): void;
}

interface OwnProps {
    promotedItem: PromotedItemType;
}

interface OwnState {
    message: string;
    loading: boolean;
    isError: boolean;
}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class TokenFormComponent extends React.Component<ComponentProps, OwnState> {
    private tokenService = new TokenService(new HttpService());

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            message: '',
            loading: false,
            isError: false
        };
    }

    public componentDidMount() {
        const { user, promotedItem } = this.props;
        if (!user) {
            navigateToLoginPage(promotedItem);
            return;
        }
    }

    private onSubmitToken = async (event: Event | any): Promise<void> => {
        event.preventDefault();
        const { user, promotedItem } = this.props;
        const { value } = event.target.token;
        const validate = validator([notEmpty]);
        const { isValidate, errors } = validate(value);

        Stats.instance.activateTokenClicked(promotedItem.title);

        if (!isValidate) {
            this.setState({ message: 'Błąd walidacji: ' + errors.join(', ') });
            return;
        }

        // W trakcie ładowania nie pozwalamy wysłać kolejnego requestu.
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const tokenConfig = {
            token: value,
            productId: this.props.promotedItem.productId,
            user,
        };

        try {
            const { message } = await this.tokenService.activeToken(tokenConfig);
            this.setState({ loading: false, isError: false });
            this.props.setTokenStatusActivate(this.props.promotedItem.id);
            this.props.closeTokenForm(this.props.promotedItem.id);
            const messageConfig = this.generateMessageConfig(message, this.props.isPlayable);
            this.props.showMessage(this.props.promotedItem.id, messageConfig);
            this.props.setAccessToTrue();
        } catch (error) {
            this.setState({
                message: error.message,
                loading: false,
                isError: true
            });
        }
    };

    private clearMessageState() {
        this.setState({ message: '' });
    }

    private closeTokenForm = () => {
        this.props.closeTokenForm(this.props.promotedItem.id);
        this.clearMessageState();
    };

    private onInputChange = () => {
        this.clearMessageState();
    };

    public render() {
        const { loading, message, isError } = this.state;

        // W przypadku braku usera nie renderujemy komponentu TokenForm
        if (!this.props.user) {
            return null;
        }

        return (
            <div className='promobox__token-overlay overlay'>
                <Spinner visible={loading} />
                <div
                    className='close-icon overlay__close'
                    onClick={this.closeTokenForm}
                >
                    <i className='icon icon-cancel'></i>
                </div>

                <div className='token-box'>
                    <form onSubmit={this.onSubmitToken}>
                        <div className='token-box__form token-form'>
                            <Input
                                type='text'
                                name='token'
                                onChange={this.onInputChange}
                                inputClassName='token-form__value'
                                placeholder='Wpisz kod dostępu'
                                classWrapper='token-form__wrapper'
                                disabled={loading}
                            />
                            <Input
                                type='submit'
                                name=''
                                inputClassName='token-form__activate'
                                value='Aktywuj'
                                placeholder='Wpisz kod dostępu'
                                disabled={loading}
                            />
                        </div>
                        {
                            isError &&
                            <Alert
                                notify={message}
                                success={false}
                            />
                        }
                    </form>
                </div>
            </div>
        );
    }

    private generateMessageConfig(info: string, isOnAir: boolean): MessageConfigType {
        const buttons = [];

        if (isOnAir) {
            buttons.push({
                title: 'Przejdź do odtwarzania',
                icon: 'icon-play',
                actions: [
                    initPlayer(this.props.promotedItem.id)
                ]
            });
        }

        buttons.push({
            title: 'Obejrzyj później',
            actions: [
                destroyMessage(this.props.promotedItem.id)
            ]
        });

        return {
            info,
            icon: 'icon-crc-ok',
            title: 'Dziękujemy!',
            buttons
        };
    }
}

const mapStateToProps = (state: AppStorageDataType, props: OwnProps): ConnectedState => {
    return {
        user: state.user,
        isPlayable: isPlayable(state)(props.promotedItem.id)
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps): ConnectedDispatch => {
    return {
        closeTokenForm(id) {
            dispatch(closeTokenForm(id));
        },
        setTokenStatusActivate(id) {
            dispatch(setTokenStatusActivate(id));
        },
        showMessage(id, messageConfig: MessageConfigType) {
            dispatch(showMessage(id, messageConfig));
        },
        showLoader() {
            dispatch(showLoader());
        },
        hideLoader() {
            dispatch(hideLoader());
        },
        setAccessToTrue() {
            dispatch(setAccess(true, props.promotedItem.gmID));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenFormComponent);
