import * as React from 'react';
import { PromotedItemType } from 'app/config-object-type';
import buildItemInfo from '../../helpers/build-item-info';

interface Props {
    media: PromotedItemType;
}

const Infobox: React.StatelessComponent<Props> = ({ media }) => (
    <article className='info-box'>
        <div
            className='info-box__content'
            dangerouslySetInnerHTML={{ __html: buildItemInfo(media)}}
        ></div>
    </article>
);

export default Infobox;
