import { createSelector } from 'reselect';
import getNow from './get-now';
import memoize from 'lodash.memoize';
import getItem from './get-item';
import getItemsList from './get-items-list';
import hasAccess from "./has-access";
import getAccessCache from "./get-access-cache";
import getUser from "./get-user";
import { AppStorageDataType, AccessCachType } from "../app-storage-data-type";
import { UserInterface } from "../actions/set-user";
import { PromotedItemType } from "../../config-object-type";
import customCreateSelector from './custom-create-selector';

/**
 * Zwraca czy element o podanym ID jest odtwarzalny w tym momencie
 */
const isPlayable = customCreateSelector<AppStorageDataType, number, PromotedItemType[], UserInterface, AccessCachType, (id: string) => boolean>(
    [
        getNow,
        getItemsList,
        getUser,
        getAccessCache
    ],
    (now, promotedItems, user, accessCache) => {
        return memoize(
            (id: string) => {

                // Ponieważ w czasie definicji tego selectora nie wiemy jekiego ID będziemy szukać
                // Jako dependency selektora ustawiamy całą listę i dopiero tutaj korzystamy
                // z selectora `getItem`.
                let item = getItem({
                    promotedItems
                })(id);

                // Jeżeli nie ma informacji od kiedy element jest odtwarzalny to nie jest odtwarzalny
                if (typeof item.playableStart === 'undefined') return false;

                // Jeżeli mamy informację że usera nie ma dastępu do elementu to element nie jest odtwarzalny
                if (hasAccess({
                    user,
                    accessCache
                })(item.gmID) === false) return false;

                return new Date(item.playableStart).getTime() <= now && new Date(item.playableEnd).getTime() >= now;
            }
        );
    }
);

export default isPlayable;
