import * as React from 'react';
import IplaLogo, { LOGO_TYPES } from './ipla-logo';

const NotSupportInfo: React.StatelessComponent<{}> = () => (
    <div className='container-fluid no-items ie-container'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <IplaLogo type={LOGO_TYPES['LIGHT']} logoAlt='ipla.tv'/>
                    <p className='no-items__info'>
                        Brak materiału na Twojej przeglądarce<br />
                        Skorzystaj z przeglądarki Chrome, Firefox, Opera lub z aplikacji IPLA w telefonie z systemem Android/iOS.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default NotSupportInfo;
