import * as React from "react";

import Header from "./header";

interface Props {}

const Layout: React.StatelessComponent<Props> = ({ children }) => (
  <div className="layout theme theme--light">
    <div className="page-container">
      <Header />
      <main className="page-body" role="main">
        {children}
      </main>
    </div>
  </div>
);

export default Layout;
