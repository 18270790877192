import { ACTION_TYPE } from '.';

export type CloseTokenFormActionType = {
    type: ACTION_TYPE.CLOSE_TOKEN_FORM,
    payload: {
        promotedItemId: string
    }
};

export function closeTokenForm(promotedItemId: string): CloseTokenFormActionType {
    return {
        type: ACTION_TYPE.CLOSE_TOKEN_FORM,
        payload: {
            promotedItemId
        }
    };
}
