import customCreateSelector from './custom-create-selector';
import memoize from 'lodash.memoize';
import getItemsList from './get-items-list';
import getItem from './get-item';

const getEventStartTime = customCreateSelector(
    [
        getItemsList
    ],
    (promotedItems) => {
        return memoize(
            (id: string) => {
                const item = getItem({
                    promotedItems
                })(id);

                return item.eventStartTime || item.playableStart;
            }
        );
    }
);

export default getEventStartTime;
