import find from 'lodash/find';

export const updateCollection = (array: any[], matches: object, updates: object) => {
    let foundMatches = find(array, matches);
    foundMatches = {
        ...foundMatches,
        ...updates
    };
    return array.map((item) => {
        return (item.id === foundMatches.id) ? foundMatches : item;
    });
};

export function findTheClosestObject<T>(
    data: Array<T>,
    compareKey: Array<string>,
    compareValue: number): T {
    return data.reduce((prev, curr) => {
        const valuePrev = getValueByPath(prev, compareKey);
        const valueCurr = getValueByPath(curr, compareKey);
        return (
            Math.abs(valuePrev - compareValue) > Math.abs(valueCurr - compareValue)
                ? curr
                : prev);
    });
}

export function getValueByPath(data: Object, path: Array<string>) {
    return path.reduce((prev, curr) => (prev && prev[curr]) ? prev[curr] : null, data);
}
