import { ACTION_TYPE } from '.';

export interface UserInterface {
    userId: string;
    deviceId: string;
    clientId: string;
    session: {
        key: string,
        id: string,
        keyExpirationTime: number
    };
}

export type SetUserActionType = {
    type: ACTION_TYPE.SET_USER,
    value: UserInterface
};

export function setUser(value: UserInterface): SetUserActionType {
    return {
        type: ACTION_TYPE.SET_USER,
        value
    };
}
