import { AppStorageDataType } from '../app-storage-data-type';

/**
 * Zwraca aktualny czas zapisany w state.
 */
const getNow = (state: AppStorageDataType) => {
    let { time, day } = state.debugSettings;

    if (state.debugMode) {
        if (time && day) {
            const [hours, minutes] = time.split(':');
            const date = new Date(day);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            return date.getTime();
        }

        if (time) {
            let date = new Date();
            let [hours, minutes] = time.split(':');
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            return date.getTime();
        }

        if (day) {
            const date = new Date(day);
            return date.getTime();
        }
    }

    return state.now;
};
export default getNow;
