import throttle from 'lodash.throttle';

class WindowResizeService {
    private readonly listeners = new Set<OnResizeListener>();
    private readonly resizeListener: EventListener;
    private static readonly ON_RESIZE_DELAY = 500;

    constructor() {
        const listeners = this.listeners;
        this.resizeListener = throttle(function (event: UIEvent) {
            const payload = {
                screen,
                outerWidth,
                outerHeight,
                innerWidth: (event.target as Window).innerWidth
            };
            listeners.forEach((fn) => fn(payload));
        }, WindowResizeService.ON_RESIZE_DELAY);

        window.addEventListener('resize', this.resizeListener);
    }

    public onResize(fn: OnResizeListener): void {
        this.listeners.add(fn);
    }

    public removeAllListeners() {
        window.removeEventListener('resize', this.resizeListener);
        this.listeners.clear();
    }

}

export default {
    createListener() {
        return new WindowResizeService();
    }
};

export type OnResizeListener = (params: OnResizePayload) => void

export type OnResizePayload = {
    screen: Screen,
    outerWidth: number,
    outerHeight: number,
    innerWidth: number
}
