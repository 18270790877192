import { ACTION_TYPE } from '.';
import { DebugSettings } from '../app-storage-data-type';

export type SetDebugSettingsAction = {
    type: ACTION_TYPE.SET_DEBUG_SETTINGS,
    settings: Partial<DebugSettings>
};

/**
 * Akcja ustawiająca parametry trybu testowego
 */
export function setDebugSettings(settings: Partial<DebugSettings>): SetDebugSettingsAction {
    return {
        type: ACTION_TYPE.SET_DEBUG_SETTINGS,
        settings
    };
}
