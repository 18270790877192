import { Store } from 'redux';

import { AppStorageDataType } from '../../storage/app-storage-data-type';
import { enableDebugMode } from '../../storage/actions/enable-debug-mode';
import { disableDebugMode } from '../../storage/actions/disable-debug-mode';

const DEBUG_ACTIVE_KEY = 'lp-debug-active';
const DEBUG_SETTINGS_KEY = 'lp-debug-settings';

/**
 * Serwis odpowiadający za obsługę trybu testowego:
 *  - synchronizacja danych z `localStorage`
 *  - udostępnienie możliwości aktywacja trybu testowego z konsoli przeglądarki
 */
export default class DebugMode {

    /**
     * Czy tryb testowy jest włączony.
     *
     * Odpowiedź na podstawie danych zapisanych w localStorage.
     * Moduły mające dostęp do Reduxa powinny pobierać tę informację ze stora
     */
    public static isDebugActive(): boolean {
        try {
            return !!window.localStorage.getItem(DEBUG_ACTIVE_KEY);
        } catch (e) {
            return false;
        }
    }

    /**
     * Zwraca ustawienia trybu testowego.
     *
     * Odpowiedź na podstawie danych zapisanych w localStorage.
     * Moduły mające dostęp do Reduxa powinny pobierać tę informację ze stora
     */
    public static getDebugSettings() {
        try {
            return JSON.parse(window.localStorage.getItem(DEBUG_SETTINGS_KEY)) || {};
        } catch (e) {
            return {};
        }
    }

    constructor(store: Store<AppStorageDataType>) {

        // Przy starcie modułu zapisujemy stan trybu testowego do localStorage
        DebugMode.updateDebugData(store.getState());

        // Przy kazdej zmianie store zapisujemy stan trybu testowego do localStorage
        store.subscribe(() => {
            DebugMode.updateDebugData(store.getState());
        });

        // Udostępnienie publicznej metody do właczania/wyłaczania trybu testowego
        window['setDebugMode'] = (value: boolean) => {
            store.dispatch(value ? enableDebugMode() : disableDebugMode());
        };
    }

    /**
     * Zapisuje ustawiania trybu testowego do localStorage
     *
     * @param state
     */
    private static updateDebugData(state: AppStorageDataType) {
        try {
            if (state.debugMode) {
                window.localStorage.setItem(DEBUG_ACTIVE_KEY, '1');
            } else {
                window.localStorage.removeItem(DEBUG_ACTIVE_KEY);
            }

            window.localStorage.setItem(DEBUG_SETTINGS_KEY, JSON.stringify(state.debugSettings));
        } catch (e) {}
    }
}
