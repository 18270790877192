import { HttpServiceInterface } from '../http/http-service';
import { UserInterface } from '../../storage/actions/set-user';
import { createSessionToken } from '../../helpers/session-helper';

export interface TokenServiceInterface {
    activeToken(config: TokenConfig): Promise<TokenServiceResponse>;
}

type TokenServiceResponse = {
    message: string;
};

export type TokenConfig = {
    token: string;
    productId: {
        id: string;
        type: string;
        subType: string;
    };
    user: UserInterface;
};

export class TokenService implements TokenServiceInterface {

    private lastTokenValue: string = null;

    constructor(private httpService: HttpServiceInterface) {
    }

    public activeToken(config: TokenConfig): Promise<TokenServiceResponse> {
        return new Promise<TokenServiceResponse>(async (resolve, reject) => {
            // Zabezpieczenie przed monkey testing
            if (this.lastTokenValue === config.token) {
                reject({ message: 'Ten token został już wysłany' });
                return;
            }

            this.lastTokenValue = config.token;

            try {
                const purchaseCodeProduct = await this.fetchPurchaseCodeProduct(config.productId, config.token, config.user);

                // Jeśli GM zwróci błąd
                if (purchaseCodeProduct && typeof purchaseCodeProduct.code === 'number') {
                    reject({ message: purchaseCodeProduct.data.userMessage || purchaseCodeProduct.data.message || purchaseCodeProduct.message });
                    return;
                }

                const paymentPath = this.buildPaymentPath(purchaseCodeProduct);

                if (!paymentPath) {
                    reject({ message: 'Brak możliwości aktywacji tego produktu za pomocą kodu' });
                    return;
                }

                const order = await this.fetchOrderId(paymentPath, config.user);
                const submit = await this.submitPurchaseCode(config.token, paymentPath, order.orderId, config.user);

                if (submit.status !== 0) {
                    reject({ message: submit.message || submit.data.message });
                    return;
                }

                const requestFn = this.fetchOrderStatus.bind(this, order.orderId, config.user);
                const orderStatus = await this.requestLoop(requestFn, 10);

                if (orderStatus.status === 0) {
                    resolve({ message: orderStatus.statusUserMessage || orderStatus.statusDescription });
                    return;
                }

                if (orderStatus.status < 0) {
                    reject({ message: orderStatus.statusUserMessage || 'Nie udało się aktywować materiału' });
                    return;
                }

            } catch (err) {
                console.log(err);
                reject({ message: 'Błąd serwera' });
                // Po błędzie serwera czyścimy poprzednią wartość
                this.lastTokenValue = null;
                return;
            }
        });
    }

    private async requestLoop(requestFn: Function, n = 5): Promise<any> {
        async function sleep(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        let delay = 0;

        return new Promise(async (resolve) => {
            for (let i = 1; i < n; i++) {
                const orderStatus = await requestFn();
                const isSuccess = orderStatus.status === 0;
                const isFail = orderStatus.status < 0;

                if (isSuccess || isFail) {
                    resolve(orderStatus);
                    return;
                }

                delay += 2 * i * 1000 + 1000;
                await sleep(delay);
            }

            resolve({
                statusDescription: 'Przekroczono czas oczekiwania',
                status: -1,
            });
        });
    }

    private buildPaymentPath(product: any): any {
        const selectedOffer = product && product.offers && product.offers[0];
        const selectedOption = selectedOffer && selectedOffer.options && selectedOffer.options[0];

        if (!selectedOffer || !selectedOption) {
            return null;
        }

        return {
            productId: {
                id: product.id,
                type: product.type,
                subType: product.subType,
            },
            offer: {
                type: selectedOffer.type,
                id: selectedOffer.id,
            },
            option: {
                id: selectedOption.id,
                type: selectedOption.type,
            }
        };
    }

    private fetchOrderStatus(orderId: string, user: any): Promise<any> {
        const serviceNamespace = 'payments';
        const serviceMethod = 'getOrderStatus';
        const sessionToken = createSessionToken(user.session, serviceMethod, serviceNamespace);

        return this.httpService.requestRpc(serviceNamespace, serviceMethod, {
            authData: { sessionToken },
            clientId: user.clientId,
            orderId
        });
    }

    private fetchPurchaseCodeProduct(productId: any, token: string, user: any): Promise<any> {
        const serviceNamespace = 'navigation';
        const serviceMethod = 'getPurchaseCodeProduct';
        const sessionToken = createSessionToken(user.session, serviceMethod, serviceNamespace);

        return this.httpService.requestRpc(serviceNamespace, serviceMethod, {
            authData: { sessionToken },
            clientId: user.clientId,
            product: productId,
            purchaseCode: token,
        });
    }

    private fetchOrderId(paymentPath: any, user: any): Promise<any> {
        const serviceNamespace = 'payments';
        const serviceMethod = 'getOrderId';
        const sessionToken = createSessionToken(user.session, serviceMethod, serviceNamespace);

        return this.httpService.requestRpc(serviceNamespace, serviceMethod, {
            authData: { sessionToken },
            clientId: user.clientId,
            product: paymentPath.productId,
            offer: paymentPath.offer,
            option: paymentPath.option,
        });
    }

    private submitPurchaseCode(token: string, paymentPath: any, orderId: string, user: any): Promise<any> {
        const serviceNamespace = 'payments';
        const serviceMethod = 'submitPurchaseCode';
        const sessionToken = createSessionToken(user.session, serviceMethod, serviceNamespace);

        return this.httpService.requestRpc(serviceNamespace, serviceMethod, {
            authData: { sessionToken },
            clientId: user.clientId,
            product: paymentPath.productId,
            offer: paymentPath.offer,
            option: paymentPath.option,
            orderId,
            purchaseCode: token,
        });
    }

}
