import { HttpService } from './http/http-service';
import { setServerTime } from './server-date-time';

const TIME_URI = './time.json';

/**
 * Aktualizuje czas serwera
 */
export async function fetchServerTime(): Promise<void> {
    try {
        const http = new HttpService();
        const response = await http.request(TIME_URI);
        const date = response.headers.get('Date');

        setServerTime(new Date(date));
    } catch (e) {
        console.error('Fetch server time fail', e);
    }
}
