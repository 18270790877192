import { createStore, Store, AnyAction, Reducer, Unsubscribe, Dispatch } from 'redux';
import { reducer } from './reducer';
import { AppStorageDataType } from './app-storage-data-type';
import { AppActionType } from './actions';
import DebugMode from '../services/debug-mode';
import { setNow } from './actions/set-now';
import { getTime } from '../services/server-date-time';

import deepClone from 'lodash.clonedeep';
import AccessCache from 'app/services/access-cache';

let instance: AppStorage;

const TIME_UPDATE_INTERVAL = 10000;

export default class AppStorage implements Store<AppStorageDataType> {
    public dispatch: Dispatch<AnyAction>;

    private _storage: Store<AppStorageDataType>;

    /**
     * Utrzymuje wsteczną kompatybilność klasy z poprzednią implementacją
     */
    get storage() {
        return this;
    }

    constructor() {
        this._storage = createStore<AppStorageDataType, AppActionType, any, any>(reducer, window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']());
        this.dispatch = this._storage.dispatch;

        // Uruchamiamy obsługę trybu testowego.
        new DebugMode(this);

        new AccessCache(this);

        // W intervale uaktualniamy wartość `now` w store, aby reszta elemetów stony dynamicznie reagowała na zmieny czasu.
        window.setInterval(() => {
            this.dispatch(setNow(getTime()));
        }, TIME_UPDATE_INTERVAL);

        // Tutaj aktualizujemy czas storny przy każdej akcji,
        // ale żeby nie wpaść w pętle tylko wtedy gdy różnica
        // między zapisanym czasem a aktuwnym wynosi ponad 1s.
        // TODO(mborecki) napisać to jakoś bez IF i Math.abs
        this.subscribe(() => {
            let time = getTime();
            if (Math.abs(this.getState().now - time) > 1000 ) {
                this.dispatch(setNow(time));
            }
        })
    }

    public getState(): AppStorageDataType {
        let state = this._storage.getState();

        // Jeżeli tryb testowy jest włączony to w locie modyfikujemy stan.
        if (state.debugMode) {
            return AppStorage.applyDebugSettings(state);
        }

        return state;
    }

    public subscribe(listener: () => void): Unsubscribe {
        return this._storage.subscribe(listener);
    }

    public replaceReducer(nextReducer: Reducer<AppStorageDataType, AnyAction>): void {
        this._storage.replaceReducer(nextReducer);
    }

    /**
     * Funkcja modyfikująca aktualny stan store według logiki trybu developerskiego.
     */
    private static applyDebugSettings(state: AppStorageDataType): AppStorageDataType {

        try {
            if (state.debugMode) {
                const debugState: AppStorageDataType = deepClone(state);
                const { isLimitExceeded } = debugState.debugSettings;

                if (typeof isLimitExceeded !== 'undefined') {
                    debugState.promotedItems.forEach((item) => {
                        item.limitExceeded = isLimitExceeded;
                    });
                }

                return debugState;
            }
        } catch (e) {
            console.error(e);
            return state;
        }

        return state;
    }

    static get instance(): AppStorage {
        if (!instance) {
            instance = new AppStorage();
        }
        return instance;
    }
}
