import getUser from "./get-user";
import getAccessCache from "./get-access-cache";
import memoize from "lodash.memoize";
import { AppStorageDataType, AccessCachType } from "../app-storage-data-type";
import { UserInterface } from "../actions/set-user";
import customCreateSelector from "./custom-create-selector";

const hasAccess = customCreateSelector< Pick<AppStorageDataType, 'user' | 'accessCache'>, UserInterface, AccessCachType, ((gmID: { cpid: number; id: string; }) => boolean)>(
    [
        getUser,
        getAccessCache
    ],
    (user, cache) => {
        return memoize(
            (gmID: {cpid: number, id: string}) => {

                try {
                    let access = cache[user.userId][`${gmID.cpid}_${gmID.id}`]

                    return typeof access === 'boolean' ? access : null;
                } catch(e){
                    //noop
                }

                return null;
            }
        )
    }
);

export default hasAccess;
