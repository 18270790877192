export interface HttpServiceInterface {
    request(input: string, requestConfig?: RequestInit): Promise<Response>;
    requestRpc(namespace: string, serviceName: string, params?: any): Promise<Response>;
}

export type Response = {
    data: any;
    headers: Headers;
};

export const GET_MEDIA_RPC_URL = 'https://b2c-www.redefine.pl/rpc';

export class HttpService implements HttpServiceInterface {

    public async request(input: string, requestConfig?: RequestInit): Promise<Response> {
        const response = await fetch(input, requestConfig);

        try {
            return {
                data: await response.json(),
                headers: response.headers
            };
        } catch (e) {
            return {
                data: null,
                headers: response.headers
            };
        }
    }

    public async requestRpc(namespace: string, serviceName: string, params: any = {}): Promise<Response> {
        const response = await this.request(`${GET_MEDIA_RPC_URL}/${namespace}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify({
                id: 1,
                jsonrpc: '2.0',
                method: serviceName,
                params: {
                    ua: "www_iplatv/12345",
                    ...params,
                },
            })
        });

        return response.data.result || response.data.error;
    }

}
