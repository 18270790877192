import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { Provider } from 'react-redux';
import AppStorage from './storage';
import AppContainer from './components/app-container';

const store = AppStorage.instance.storage;

export const render = (root : HTMLElement) => {
    ReactDOM.render(
        <Provider store={store}>
            <AppContainer />
        </Provider>,
        root
    );
};
