import getItemsListToShow from './get-item-list-to-show';
import { AppStorageDataType } from "../app-storage-data-type";
import customCreateSelector from "./custom-create-selector";

/**
 * Zwraca ID aktywnego elementu. Pierwszeństwo na wartość zapisana w state,
 * jeżeli jej nie ma lub nie zostanie znaleziona to zwracany jest ID pierwszego elementu z listy.
 */
const getActiveItem = customCreateSelector(
    [
        getItemsListToShow,
        (state: AppStorageDataType) => state.activePromotedItemID
    ],
    (list, valueFormState) => {
        return (valueFormState && list.find(item => item.id === valueFormState)) || list[0];
    }
);

export default getActiveItem;
