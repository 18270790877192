import { APP_GLOBAL_STATE, AppStorageDataType } from "../app-storage-data-type";
import { UpdateConfigActionType } from "../actions/update-config";
import getActiveItem from "app/storage/selectors/get-active-item";
import setAccess from "./set-access";
import isPlayable from "../selectors/is-playable";
import getUser from "../selectors/get-user";

export default function updateConfigReducer(state: AppStorageDataType, action: UpdateConfigActionType) : AppStorageDataType {

    let newState: AppStorageDataType = {
        ...state,
        appState: APP_GLOBAL_STATE.ITEMS_LIST,
        promotedItems: action.payload.promotedItems,
        extraPriorities: action.payload.extraPriorities || []
    };
    let items = newState.promotedItems;

    if (action.payload.limitExceeded) {
        newState.promotedItems.forEach(item => {
            item.limitExceeded = true;
        });
    }

    // Jeżeli mamy zapisane informację o queryParams to próbujemy ich użyć
    if (newState.queryParams) {

        // Jeżeli mamy narzucone ID to ustawiamy go jako aktywny element
        if (newState.queryParams.id) {
            newState.activePromotedItemID = newState.queryParams.id;
        }

        // Jeżeli mamy podane ID i informację o dostępności to dodajemy ją do obiektu
        if (newState.queryParams.id && typeof newState.queryParams.hasAccess !== 'undefined') {
            let item = items.find(item => {
                return item.id === newState.queryParams.id
            });

            if (item) {
                // Jeżeli mamy informacje o dostępie do elementu, zapisujemy ją w `accessCache`
                newState = setAccess(newState, {
                    gmID: item.gmID,
                    value: newState.queryParams.hasAccess
                });
            }
        }

        // Aktywnym elementem jest ten o podanym ID lub pierwszy z listy
        let activeItem = getActiveItem(newState);

        // Jeżeli mamy aktywny element to...
        if (activeItem) {

            // Jeżeli mamy ustawiony autoplay, jesteśmy zalogowani i informacje że aktywny element jest odtwarzalny uruchamiamy playera
            if (
                    newState.queryParams.autoplay &&
                    isPlayable(newState)(activeItem.id) &&
                    getUser(newState)
                ) {
                activeItem.isPlayerShowed = true;
            }
        }

        // Chcemy to zrobić tylko raz, na wypadek gdybyśmy wielokrotnie ładowali dane do aplikacji.
        delete newState.queryParams;
    }

    return newState
}
