import { ACTION_TYPE } from '.';

export type ShowTokenFormType = {
    type: ACTION_TYPE.SHOW_TOKEN_FORM,
    payload: {
        promotedItemId: string
    }
};

export function showTokenForm(promotedItemId: string): ShowTokenFormType {
    return {
        type: ACTION_TYPE.SHOW_TOKEN_FORM,
        payload: {
            promotedItemId
        }
    };
}
