import * as React from 'react';

interface Props {
    header: string;
    message: string;
}

const NotifyBoxPromoted: React.StatelessComponent<Props> = ({ header, message }) => (
    <div className='promobox__notify-box'>
        <div className='notify-box'>
            <p className='notify-box__header'>{header}</p>
            <p className='notify-box__content'>
                {message}
            </p>
        </div>
    </div>
);

export default NotifyBoxPromoted;
