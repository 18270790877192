import { TaskConfigInterface } from '../services/player/player-service';

export const buildPrePlayDataUrl = ({ type, id }: TaskConfigInterface): string => {
    return `./${id}-${type}.json`;
};

export function getParameterByName(name: string) {
    let match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
