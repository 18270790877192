import * as React from 'react';

interface Props {
    text?: string;
    size?: string;
    className?: string;
    visible: boolean;
}

const Spinner: React.StatelessComponent<Props> = ({ text, size = 'medium', className, visible }) => {
    let classes;

    if (size) {
        classes = `spinner--${size}`;
    }

    return (
        visible && <div className={`spinner ${classes} ${className}`}>
            {text && <span className='spinner__text'>{text}</span>}
            <svg className='spinner__dots' viewBox='0 0 40 15' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='5' cy='15' r='5' />
                <circle cx='20' cy='15' r='5' />
                <circle cx='35' cy='15' r='5' />
            </svg>
        </div>
    );
};

export default Spinner;
