import { AppActionType } from './storage/actions';

export type ConfigObjectType = {
    /**
     * Lista elementów promowanych na stronie.
     *
     * @type {PromotedItemType[]}
     */
    promotedItems: PromotedItemType[],

    /**
     * Pokazuj wszystkie przyszłe promowane elementy.
     *
     * @type {boolean}
     * @default false
     */
    showMultipleItems: boolean,

    /**
     * Adres pod który przekierowywujemy w celu zalogoawnie usera.
     *
     * @type {string}
     */
    loginURL: string,

    /**
     * Czy w przypadku próby zakupu przekierowywać od razu
     *
     * @default true
     */
    autoRedirectToPaymentPage: boolean,

    /**
     * Czy po zakończeniu odtwarzania wracać na listę materiałów promocyjnych
     *
     * @default false
     */
    showItemListOnPlaybackEnd: boolean,

    /**
     * Sortowanie elementów z tej samej godziny
     */
    extraPriorities?: Array<ExtraPriorities>

    /**
     * ID aplikacji Google Cast Receiver
     */
    chromecastAppID?: string
};

export type ExtraPriorities = {
    /**
     * Data od której zaczynamy promować element. (format ISO 8601)
     */
    promoteStart: string;
    /**
     * Data do której promujemy element. (format ISO 8601)
     */
    promoteEnd: string;
    itemId: string;
    /**
     * Priorytet elementu promowanego.
     * Im wyższa wartość tym wyżej element powinien znajdować się na liście.
     * Jest to współczynnik sortowania o najwyższym priorytecie.
     */
    priority: number;
};

export type ButtonType = {
    title: string;
    icon?: string;
    actions: AppActionType[];
};

export type MessageConfigType = {
    title: string;
    info: string;
    icon?: string;
    buttons: ButtonType[];
};

export type PromotedItemType = {
    /**
     * ID promowanego elementu
     */
    id: string,

    gmID: MiniMediaDefinition,

    /**
     * ProductID
     */
    productId: {
        id: string;
        type: string;
        subType: string;
    },

    /**
     * Czy wstrzymujemy możliwość zakupu,
     * ustawienie flagi determinuje wyświetlenie notyfikacji
     */
    limitExceeded: boolean,

    /**
     * Tytuł promowanego elementu.
     *
     * @type {string}
     */
    title: string,

    /**
     * Nazwa kategorii do której należy promowany materiał
     *
     * @type {string}
     */
    category?: string,

    /**
     * Data od której zaczynamy promować element. (format ISO 8601)
     *
     * @type {String}
     */
    promoteStart: string,

    /**
     * Data do której promujemy element. (format ISO 8601)
     *
     * @type {String}
     */
    promoteEnd: string,

    /**
     * Data od której można zaczac odtwarzać promowany element. (format ISO 8601)
     *
     * @type {String}
     */
    playableStart?: string,

    /**
     * Data do której można odtwarzać promowany element. (format ISO 8601)
     *
     * @type {String}
     */
    playableEnd?: string,

    /**
     * Data rozpoczęcia live, która wyświetla się przy materiale. (format ISO 8601)
     *
     * @type {String}
     */
    eventStartTime?: string,

    /**
     * Cena prezentowana w 'Cena od X'
     */
    price?: string,

    /**
     * Czy materiał może być dostepny po wpisaniu tokenu. Determinuje czy wyswietlać przycisk "Mam kod".
     */
    accessByToken?: boolean,

    /**
     * Lista pakietów które chce powiązać z promowanym elementem.
     * Brak aktualnie zastosowń w projekcie.
     * Można wykorzystać do wyświetlenia ikon pakietów na posterze promującym.
     */
    accessByPacks?: Array<PackType>,

    /**
     * Opis promowanego elementu
     */
    description?: string,

    /**
     * Identyfikator stylu prezentacji promowanego elementu
     */
    theme?: THEME_TYPE,

    /**
     * URL do strony promowanego elementu
     */
    url?: string,

    /**
     * Priorytet elementu promowanego.
     * Im wyższa wartość tym wyżej element powinien znajdować się na liście.
     */
    priority: number,

    order?: number,

    isPlayerShowed?: boolean,
    isFormTokenShowed?: boolean,

    posters: PosterType[],

    message?: MessageConfigType;

    /**
     * Czy jest to transmisja live.
     * Nie ma nic wspólnego z tym czy transmisja teraz trwa.
     *
     * @type {boolean}
     */
    isLive?: boolean,

    /**
     * Dodatkowe informacje dotyczące geolokalizacji
     */
    geoLocationInfo?: string,

    /**
     * Platformy dostępności promowanego elementu
     */
    platforms?: PLATFORMS[],

    /**
     * Typ informacji wyświetlanej w info boxie pod elementem aktywnym
     */
    infoBoxType: INFOBOX_TYPE,

    /**
     * Jeżeli infoBoxType === INFOBOX_TYPE.CUSTOM w tym polu znajduje się
     * treść do wyświetlenia w info boxie pod elementem aktywnym.
     * Może to być HTML.
     */
    infoBoxContent?: string,

    /**
     * Pod jaką domeną jest portal obsługujący logowanie/płatności dla tego elementu
     * Używane do celów testowych.
     */
    domain?: string
};

export enum PLATFORMS {
    MOBILE = 'mobile',
    PC = 'pc',
    STB = 'stb',
    TV = 'tv'
}

export enum THEME_TYPE {
    DARK = 'dark',
    LIGHT = 'light'
}

export type PackType = {
    name: string,
    url: string
};

export type PosterType = {
    size: {
        width: number,
        height: number
    },
    src: string
};

export enum INFOBOX_TYPE {
    /**
     * element z tą wartością powinien zawierać pole `infoBoxContent` z treścią dla info boxa.
     */
    CUSTOM = 'custom',

    /**
     * Domyślny tekst w info boxie.
     */
    STANDARD = 'standard',

    KSW = 'ksw',

    LIGA_MISTRZOW = 'liga-mistrzow',

    FEN = 'fen',

    NONE = 'none'
}

export type MiniMediaDefinition = {
    /**
     * ID promowanego elementu w GetMedii
     */
    id: string,

    /**
     * CPID promowanego elementu, potrzebny do playera
     */
    cpid: number
};
