import * as React from 'react';

import { APP_GLOBAL_STATE } from 'app/storage/app-storage-data-type';
import Spinner from '../state-less/spinner';

interface OwnProps {
    appState: APP_GLOBAL_STATE;
}

export default class PageLoaderContainer extends React.Component<OwnProps> {
    public render() {
        const isSpinnerVisible = this.props.appState === APP_GLOBAL_STATE.INIT_APP;
        return (
            isSpinnerVisible && <div className='promobox__poster'>
                <Spinner visible={true} text='Trwa ładowanie...' />
            </div>
        );
    }
}
