import { AppStorageDataType } from "../app-storage-data-type";

/**
 * Zwraca pełną liste elementów promowanych.
 *
 * @param partialState - Ograniczamy wymagania nt. typu `state` aby łatwiej korzystać z tego selektora poza środowiskiem `react-redux`.
 * Dzięki temu możemy np. użyć ten selector wewnątrz `isPlayable` bez potrzeby przekazywania całego stanu.
 */
const getItemsList = (partialState: Pick<AppStorageDataType, "promotedItems">) => partialState.promotedItems;
export default getItemsList;
