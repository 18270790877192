import AppStorage from "app/storage";
import getActiveItem from "app/storage/selectors/get-active-item";

let instance: Stats;

const GPRISM_URL = '//pro.hit.gemius.pl/redot.gif?l=41/';
const GPRISM_ID = 'nXAwEjOaL5RneOgnbZdXsJdSfbuUt0ei1rpFPyshg2X._7';
const GTM_ACCOUNTS = [
    'GTM-T4SKLT8'
];

export default class Stats {

    static get instance(): Stats {
        if (!instance) {
            instance = new Stats();
        }
        return instance;
    }

    static injectGTM(accounts: string[] = GTM_ACCOUNTS) {
        accounts.forEach(id => {
            let noscript = document.createElement('noscript');
            noscript.innerHTML = `
            <!-- Google Tag Manager ${id} noscript -->
            <iframe src="//www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `;

            let script = document.createElement('script');
            script.innerHTML = `
            <!-- Google Tag Manager ${id} script -->
                (function(w,d,s,l,i) {
                    w[l] = w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async = true;
                    j.src = '//www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${id}');
            `;

            window.document.head.appendChild(noscript);
            window.document.head.appendChild(script);
        });
    }

    private browseHitSended = false;

    public initPageStartWatcher() {
        let unsubscribe : () => void;

        const callback = () => {
            if (!this.browseHitSended && getActiveItem(AppStorage.instance.getState())) {
                this.browseHitSended = true;
                this.pageStarted();
                unsubscribe();
            }
        }

        unsubscribe = AppStorage.instance.subscribe(callback);
    }

    public goToPageClicked(): Promise<void> {
        return this.gemiusHit(GPRISM_GOAL.GO_TO_PAGE)
            .catch(() => {/* noop */});
    }

    public buyButtonClicked(mediaTitle: string): Promise<void> {
        return this.gemiusHit(GPRISM_GOAL.BUY, { media: mediaTitle })
            .catch(() => {/* noop */});
    }

    public activateTokenClicked(mediaTitle: string): Promise<void> {
        return this.gemiusHit(GPRISM_GOAL.ACTIVATE, { media: mediaTitle })
            .catch(() => {/* noop */});
    }

    public pageStarted(): Promise<void> {
        return this.gemiusHit(GPRISM_GOAL.BROWSE)
            .catch(() => {/* noop */});
    }

    private gemiusHit(goal: GPRISM_GOAL, params: { media?: string } = {}): Promise<void> {
        return new Promise(resolve => {
            let et = this.getGPrismEventType(goal);
            let media = params.media || this.getActiveMediaTitle() || '';

            let extras = [
                'c=' + this.gprismEscape('ipla-www'),
                'GoalName=' + this.gprismEscape(goal)
            ];

            let href = [
                'Cat=' + this.gprismEscape('Landing_Page'),
                'm=' + this.gprismEscape(media),
                'd=' + this.gprismEscape('Cyfrowy Polsat'),
                'l=' + this.gprismEscape('live')
            ];

            let url = `${GPRISM_URL}&id=${GPRISM_ID}&et=${et}&extra=${encodeURIComponent(extras.join('|'))}&href=${encodeURIComponent(href.join('|'))}`;

            let img = new Image();
            img.addEventListener('load', () => {
                resolve();
            });

            img.src = url;

            setTimeout(() => {
                resolve();
            }, 200);
        });
    }

    private getGPrismEventType(goal: GPRISM_GOAL) {
        switch (goal) {
            case GPRISM_GOAL.BROWSE:
                return 'view';

            default:
                return 'action';
        }
    }

    private gprismEscape(str: string) {
        if (str.replace) {
            return str.replace(/[\&\= ]/g, '_');
        }
        return str;
    }

    private getActiveMediaTitle() {
        let activeItem = getActiveItem(AppStorage.instance.getState());
        if (activeItem) {
            return activeItem.title;
        }

        return null;
    }
}


enum GPRISM_GOAL {
    GO_TO_PAGE = 'Interfejs/Landing_Page/Przejdź_Do_Strony',
    BUY = 'Interfejs/Landing_Page/Kup',
    ACTIVATE = 'Interfejs/Aktywacja_Kodu',
    BROWSE = 'Interfejs/Przeglądanie'
}
