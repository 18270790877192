import { AppStorageDataType } from "../app-storage-data-type";

export default function setAccess(state: AppStorageDataType, action: {
    gmID: {
        id: string,
        cpid: number
    },
    value: boolean
}) : AppStorageDataType {

    let user = state.user;

    if (user) {
        let newState = {
            ...state
        }

        let userCache = (newState.accessCache[user.userId] = newState.accessCache[user.clientId] || {});

        // Ponieważ aktualnie rozróżniamy tylko dwa stany: Jest dostęp / Nie wiemy czy jest dostęp
        // W celu ograniczenia ilości danych zapisywanych w LS usuwamy odpowiednie pole obiektu
        // zamiast ustawiać wartość `false`
        if (action.value) {
            userCache[`${action.gmID.cpid}_${action.gmID.id}`] = true;
        } else {
            delete userCache[`${action.gmID.cpid}_${action.gmID.id}`];
        }

        return newState;
    }

    return state;
}
