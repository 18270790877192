import { ACTION_TYPE } from '.';

export type SetNowAction = {
    type: ACTION_TYPE.SET_NOW,
    value: number
};

export function setNow(value: number): SetNowAction {
    return {
        type: ACTION_TYPE.SET_NOW,
        value
    };
}
