import { SetTitleActionType } from './set-title';
import { SetUserActionType } from './set-user';
import { ClosePaymentInfoActionType } from './close-payment-info';
import { ClosePlayerActionType } from './close-player';
import { CloseTokenFormActionType } from './close-token-form';
import { HideTestingPanelActionType } from './hide-testing-panel';
import { InitPlayerActionType } from './init-player';
import { PlaybackEndedActionType } from './playback-ended';
import { PlayerErrorActionType } from './player-error';
import { RestartActionType } from './restart';
import { ShowPaymentInfoActionType } from './show-payment-info';
import { ShowTokenFormType } from './show-token-form';
import { ShowTestingPanelActionType } from './show-testing-panel';
import { ShowLoaderActionType } from './show-loader';
import { UpdateConfigActionType } from './update-config';
import { SetTokenStatusActivateType } from './set-token-status-activate';
import { SetDebugSettingsAction } from './set-debug-settings';
import { EnableDebugModeAction } from './enable-debug-mode';
import { DisableDebugModeAction } from './disable-debug-mode';
import { DestroyMessageType, ShowMessageType } from './message';
import { SetNowAction } from './set-now';
import { SetActivePromotedItemActionType } from './set-active-promoted-item';
import { GoToPurchasePageAction } from './go-to-purchase-page';
import { SetAccessAction } from './set-access';

export enum ACTION_TYPE {
    SET_TITLE,
    SET_USER,

    UPDATE_CONFIG,
    INIT_PLAYER,
    PLAYER_ERROR,
    CLOSE_PLAYER,
    SHOW_TOKEN_FORM,
    CLOSE_TOKEN_FORM,
    TOKEN_STATUS_ACTIVATE,
    SHOW_PAYMENT_INFO,
    DESTROY_MESSAGE,
    SHOW_MESSAGE,
    CLOSE_PAYMENT_INFO,
    PLAYBACK_ENDED,
    RESTART,
    SHOW_LOADER,
    SET_DEBUG_MODE,
    SET_DEBUG_SETTINGS,
    HIDE_TESTING_PANEL,
    SHOW_TESTING_PANEL,
    ENABLE_DEBUG_MODE,
    DISABLE_DEBUG_MODE,
    SET_ACTIVE_PROMOTED_ITEM,

    SET_NOW,
    GO_TO_PURCHASE_PAGE,
    SET_ACCESS
}

export type AppActionType = SetTitleActionType | SetUserActionType | ClosePaymentInfoActionType | ClosePlayerActionType |
    CloseTokenFormActionType | InitPlayerActionType | PlaybackEndedActionType |
    PlayerErrorActionType | RestartActionType | ShowPaymentInfoActionType |
    ShowTokenFormType | ShowLoaderActionType | UpdateConfigActionType | DestroyMessageType | ShowMessageType |
    SetTokenStatusActivateType | SetDebugSettingsAction | EnableDebugModeAction | DisableDebugModeAction |
    ShowTestingPanelActionType | HideTestingPanelActionType | SetActivePromotedItemActionType | SetNowAction |
    GoToPurchasePageAction | SetAccessAction;
