import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AppStorageDataType } from '../../storage/app-storage-data-type';
import { MessageConfigType, PromotedItemType } from '../../config-object-type';
import { AppActionType } from '../../storage/actions';
import ButtonComponent from '../state-less/button';

interface ConnectedState {
}

interface ConnectedDispatch {
    actionClick(actions: AppActionType[]): void;
}

interface OwnProps {
    message: MessageConfigType;
    icon?: string;
}

interface OwnState {
}

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class Message extends React.Component<ComponentProps, OwnState> {
    public render() {
        const { message, actionClick, icon = 'icon-crc-info' } = this.props;
        return <div className='promobox__overlay overlay'>
            <div className='message-box'>
                <p className='message-box__icon'>
                    <i className={'icon ' + icon}> </i>
                </p>
                <p className='message-box__header'>{message.title}</p>
                <div className='message-box__content'>
                    <p>{message.info}</p>
                    {
                        message.buttons.map((button, index) =>
                            <ButtonComponent
                                key={index}
                                icon={button.icon}
                                title={button.title}
                                onClick={() => actionClick(button.actions)}
                            />)
                    }
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = (state: AppStorageDataType): ConnectedState => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        actionClick(actions: AppActionType[]) {
            actions.forEach((action) => {
                dispatch(action);
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
