import * as React from 'react';
import { PromotedItemType } from '../../config-object-type';

import ActionsButtons from '../state-store/actions-buttons';
import DateFormatter from '../../helpers/date-formatter';
import { Dispatch } from 'redux';
import { AppStorageDataType } from 'app/storage/app-storage-data-type';
import hasAccess from 'app/storage/selectors/has-access';
import { connect } from 'react-redux';
import getEventStartTime from '../../storage/selectors/get-event-start-time';

interface ConnectedState {
    hasAccess: boolean;
    getEventStartTime: string;
}

interface OwnProps {
    item: PromotedItemType;
}

interface ConnectedDispatch { }

interface OwnState { }

type ComponentProps = ConnectedState & ConnectedDispatch & OwnProps;

class PromoboxMetaDataComponent extends React.Component<ComponentProps, OwnState> {
    public render() {
        let { item, hasAccess, getEventStartTime } = this.props;
        let { dayText, dayNumber, monthRoman, minutesText, hoursText } = new DateFormatter(getEventStartTime);
        const hasPacketAvailable = item.accessByPacks && item.accessByPacks.length > 0;

        return (
            <div className='promobox__metadata'>
                <div className='promobox-about'>
                    {
                        item.category &&
                        <p className='category'>
                            {item.category}
                        </p>
                    }

                    {
                        item.playableStart &&
                        <p className='event-start-date'>
                            {
                                item.isLive &&
                                <span className='live'>LIVE</span>
                            }
                            &nbsp;

                        <span className='weekday'>{dayText}</span>&nbsp;
                        <span className='date-day'>{dayNumber}</span>&nbsp;
                        <span className='date-month'>{monthRoman}</span>&nbsp;
                        <span>od</span>&nbsp;
                        <span className='time'>{hoursText}:{minutesText}</span>
                        </p>
                    }

                    <h1 className='title'>{item.title}</h1>

                    {
                        item.description &&
                        <p className='description'>
                            {item.description}
                        </p>
                    }
                </div>

                <div className='promobox__access promobox-access'>

                    {
                        item.price &&
                        !hasAccess &&
                        !item.limitExceeded &&
                        <div className='price-box'>
                            <span className='price-box__text'>
                                {hasPacketAvailable ? 'Cena w pakiecie od' : 'Cena od'}
                        </span>
                            <span className='price-box__value'>
                                {item.price}
                            </span>
                            <span className='price-box__currency'>
                                PLN
                        </span>
                        </div>
                    }

                    <ActionsButtons promotedItem={item} hideTokenBtn={true} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppStorageDataType, props: OwnProps): ConnectedState => {
    return {
        hasAccess: hasAccess(state)(props.item.gmID),
        getEventStartTime: getEventStartTime(state)(props.item.id)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {};
};

const PromoboxMetaData = connect(mapStateToProps, mapDispatchToProps)(PromoboxMetaDataComponent);
export default PromoboxMetaData;
