import { ACTION_TYPE } from '.';

export type ShowLoaderActionType = {
    type: ACTION_TYPE.SHOW_LOADER,
    payload: {
        isGlobalSpinner: boolean
    }
};

export function showLoader(): ShowLoaderActionType {
    return {
        type: ACTION_TYPE.SHOW_LOADER,
        payload: {
            isGlobalSpinner: true
        }
    };
}

export function hideLoader(): ShowLoaderActionType {
    return {
        type: ACTION_TYPE.SHOW_LOADER,
        payload: {
            isGlobalSpinner: false
        }
    };
}
