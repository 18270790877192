import { HttpService } from '../http/http-service';
import { buildPrePlayDataUrl } from '../../helpers/url-helpers';
import AppStorage from 'app/storage';
import throttle from 'lodash.throttle';
import { MiniMediaDefinition } from 'app/config-object-type';

const GET_MEDIA_NOT_FOUND = 13404;
const GET_MEDIA_UNAUTHORIZED_ACCESS = 13401;
const GET_MEDIA_SERVER_ERROR = 13500;

interface PrePlayDataSuccess {
    mediaItem: any;
    reporting: any;
}

interface PrePlayDataError {
    code: number;
    message: string;
    data: any;
}

export interface TaskConfigInterface {
    type: string;
    id: string;
}

type PrePlayData = PrePlayDataSuccess & PrePlayDataError;

const RESIZE_THROTTLE = 300;

export type PlayerError = {
    type: number;
    details: any[];
};

export type PlayerAPI = {
    config(config: PlayerConfig): void;
    isReady(): Promise<void>;
    onError(cb: Function): Promise<void>;
    registerPrePlayDataPlugin(id: string, posterSrc: string): Promise<void>;
    loadMedia(gmId: MiniMediaDefinition): Promise<void>;
    destroy(): Promise<void>;
    updateGUI(): void;
};

export interface PlayerErrorTypes {
    DRM_ERROR: number;
    DRM_PAYMENT_REQUIRED: number;
    MEDIA_NOT_FOUND: number;
}

export interface PlayerConfig {
    container: HTMLElement;
    load: {
        preload: boolean,
        autoplay: boolean
    };
    user?: {
        id: string;
        session: {
            key: string,
            id: string,
            keyExpirationTime: number
        };
    };
    userAgentData: {
        portal: string;
    };
    stats?: {
        domain?: string;
    };
    options?: {
        forceAdaptiveStreaming?: boolean,
        chromecastAppID?: string,
    };
}

export interface PlayerClass {
    new(httpService?: HttpService): PlayerAPI;
    getErrorTypes(): PlayerErrorTypes;
}

const PLAYER_URL = './cp-player.js';

/**
 * Fabryka która załaduje bibliotekę playera (pilnując żeby zrobić to tylko raz)
 * i zwróci obekt spełaniający PlayerAPI do użycia przez aplikację
 */
export async function createPlayer(): Promise<PlayerClass> {
    return new Promise<PlayerClass>(resolve => {
        if (window['CyfrowyPlayer']) {
            resolve(CyfrowyPlayer);
        } else {
            const script = document.createElement('script');
            script.src = PLAYER_URL;
            script.addEventListener('load', () => {
                resolve(CyfrowyPlayer);
            });
            document.body.appendChild(script);
        }
    });
}

class CyfrowyPlayer implements PlayerAPI {
    public static getErrorTypes(): PlayerErrorTypes {
        return window['CyfrowyPlayer'].ERROR_TYPE;
    }

    private playerInstance: any;
    private prePlayDataCached = new Map<string, PrePlayData>();

    constructor(private httpService: HttpService = new HttpService()) {
        this.updateGUI = this.updateGUI.bind(this);
    }

    public config(config: PlayerConfig): void {
        window.addEventListener('resize', this.updateGUI);
        this.playerInstance = new window['CyfrowyPlayer'].PolsatBoxPlayer(config);
    }

    public async loadMedia(gmID: MiniMediaDefinition): Promise<void> {
        const state = AppStorage.instance.getState();

        const taskConfig = [
            {
                type: 'dash',
                id: gmID.id,
            },
            {
                type: 'hls',
                id: gmID.id
            }
        ];

        try {
            // Jednorazowo cachujemy odpowiedź
            this.prePlayDataCached = await this.fetchPrePlayData(taskConfig);

            if (__DEVELOPMENT__) {
                if (state.debugMode && state.debugSettings.isGMMediaSource === true) {
                    this.prePlayDataCached.clear();
                }
            }

        } catch (e) {
            return;
        }

        // Jeżeli mamy narzucony mediaID to trzeba go nadpisać.
        if (state.debugMode && state.debugSettings.mediaId) {
            gmID = {
                ...gmID,
                id: state.debugSettings.mediaId
            };
        }

        await this.playerInstance.player.load({ gmID });
    }

    public async registerPrePlayDataPlugin(id: string, posterSrc: string): Promise<void> {
        await this.isReady();
        this.playerInstance.registerPlugin({
            canDo: (task: any): boolean => {
                const state = AppStorage.instance.getState();

                if (__DEVELOPMENT__) {
                    if (state.debugMode && state.debugSettings.isGMMediaSource === true) {
                        return false;
                    }
                }
                return task.name === 'getPlayData';
            },
            run: async (task: any, playerController: any) => {

                // Pobranie z playera informacji o dostepnych funkcjonalnościach.
                const { tags } = await playerController.plugins.run(
                    { name: 'getPlatformFeatures' }
                ) as { tags: string[] };

                const isWideWineSupported = tags.some(tag => tag === 'widevine');

                // Jeżeli mamy obsługę widevine i zapamiętane dane w formacie DASH używamy ich.
                const dash = (this.prePlayDataCached.get('dash')) ? { ...this.prePlayDataCached.get('dash') } : null;
                if (isWideWineSupported && dash) {
                    // Czyszczenie cache, aby kolejne zapytania zwracały zaktulizowanie JSONy.
                    this.prePlayDataCached.clear();
                    this.checkForError(dash);
                    let mediaDefinition = await window['CyfrowyPlayer'].prePlayDataToMediaDefinition(dash);
                    mediaDefinition.images = [{
                        url: posterSrc
                    }];
                    return mediaDefinition;
                }

                // Jeżeli mamy zapamiętane dane w formacie HLS używamy ich
                const hls = (this.prePlayDataCached.get('hls')) ? { ...this.prePlayDataCached.get('hls') } : null;
                if (hls) {
                    // Czyszczenie cache, aby kolejne zapytania zwracały zaktulizowanie JSONy.
                    this.prePlayDataCached.clear();

                    // Gdy nie posiadamy kopii hls-owej, ale jednocześnie mamy kopie dash-ową
                    // Ustawiamy dodatkowo flagę którą przechwyci widok i odpowiednio zareaguje
                    if (hls.code === GET_MEDIA_NOT_FOUND && dash.mediaItem) {
                        const Player = window['CyfrowyPlayer'];
                        const MEDIA_NOT_FOUND = Player.CPError.TYPE.MEDIA_NOT_FOUND;
                        throw new Player.CPError(MEDIA_NOT_FOUND, { ...hls, isNotBrowserSupport: true });
                    }

                    this.checkForError(hls);
                    let mediaDefinition = await window['CyfrowyPlayer'].prePlayDataToMediaDefinition(hls);
                    mediaDefinition.images = [{
                        url: posterSrc
                    }];
                    return mediaDefinition;
                }

                // Jeżeli nie było nic w cache pobieramy odpowiedniego JSONa.
                const type = (isWideWineSupported) ? 'dash' : 'hls';
                const prePlayDataMap = await this.fetchPrePlayData([{ type, id }]);
                const prePlayData = prePlayDataMap.get(type);

                this.checkForError(prePlayData);

                let mediaDefinition = await window['CyfrowyPlayer'].prePlayDataToMediaDefinition(prePlayData);
                mediaDefinition.images = [{
                    url: posterSrc
                }];
                return mediaDefinition;
            }
        });
    }

    public updateGUI() {
        throttle(() => {
            this.playerInstance.updateGUI();
        }, RESIZE_THROTTLE).call(this);
    }

    public async isReady(): Promise<void> {
        return this.playerInstance.ready;
    }

    public async onError(callback: Function): Promise<void> {
        await this.isReady();
        this.playerInstance.onError.attach(callback);
    }

    public async destroy(): Promise<void> {
        window.removeEventListener('resize', this.updateGUI);
        await this.playerInstance.destroy();
    }

    private async fetchPrePlayData(taskConfig: TaskConfigInterface[]): Promise<Map<string, PrePlayData>> {

        const state = AppStorage.instance.getState();

        // Jeżeli źródłem danych ma być GM to nie ma senstu pobierać JSONów.

        if (__DEVELOPMENT__) {
            if (state.debugMode && state.debugSettings.isGMMediaSource === true) {
                return new Map<string, any>(taskConfig.map(task => {
                    return [task.type, null] as [string, any];
                }));
            }
        }

        // Jeżeli mamy narzucony mediaID to trzeba go nadpisać.
        if (state.debugMode && state.debugSettings.mediaId) {
            taskConfig = taskConfig.map(task => {
                return {
                    ...task,
                    id: state.debugSettings.mediaId
                };
            });
        }

        const tasks = taskConfig
            .map(async ({ id, type }) => {
                const url = buildPrePlayDataUrl({ type, id });
                const data = await this.httpService.request(url)
                    .then(response => response.data);

                return {
                    data,
                    type
                };
            });

        try {
            const responseItems = await Promise.all(tasks);
            const prePlayDataMap = new Map<string, any>();

            responseItems.forEach(({ data, type }) => {
                prePlayDataMap.set(type, data);
            });

            return prePlayDataMap;

        } catch (e) {
            throw new Error(e);
        }
    }

    /**
     * Sprawdza czy obiekt data nie jest błędem, jeśli jest rzuca błędem który obsluguję player
     * @param prePlayData
     */
    private checkForError(prePlayData: PrePlayData) {
        const CYFROWY_PLAYER = window['CyfrowyPlayer'];
        const MEDIA_NOT_FOUND = CYFROWY_PLAYER.CPError.TYPE.MEDIA_NOT_FOUND;
        const UNAUTHORIZED_ACCESS = CYFROWY_PLAYER.CPError.TYPE.UNAUTHORIZED_ACCESS;
        const SERVER_ERROR = CYFROWY_PLAYER.CPError.TYPE.SERVER_ERROR;
        const REQUEST_ERROR = CYFROWY_PLAYER.CPError.TYPE.REQUEST_ERROR;

        if (!prePlayData) {
            throw new CYFROWY_PLAYER.CPError(MEDIA_NOT_FOUND, prePlayData);
        }

        if (!prePlayData.code) {
            return;
        }

        switch (prePlayData.code) {
            case GET_MEDIA_NOT_FOUND:
                throw new CYFROWY_PLAYER.CPError(MEDIA_NOT_FOUND, prePlayData);

            case GET_MEDIA_UNAUTHORIZED_ACCESS:
                throw new CYFROWY_PLAYER.CPError(UNAUTHORIZED_ACCESS, prePlayData);

            case GET_MEDIA_SERVER_ERROR || prePlayData.code < 0:
                throw new CYFROWY_PLAYER.CPError(SERVER_ERROR, prePlayData);

            default:
                throw new CYFROWY_PLAYER.CPError(REQUEST_ERROR, prePlayData);

        }
    }
}
