import * as React from 'react';

interface Props {
    notify: string;
    success: boolean;
}

function getAlertType(isSuccess: boolean) {
    if (isSuccess) {
        return 'success';
    }
    return 'error';
}

const Alert: React.StatelessComponent<Props> = ({ notify, success }: Props) => (
    <p className={`token-box__${getAlertType(success)}`}>
        {notify}
    </p>
);

export default Alert;
