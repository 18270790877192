import { ACTION_TYPE } from '.';
import { ConfigObjectType } from '../../config-object-type';

export type OnlyExternalConfigType = { limitExceeded: boolean}
export type ExternalConfigObjectType = Partial<ConfigObjectType> & Partial<OnlyExternalConfigType>;

export type UpdateConfigActionType = {
    type: ACTION_TYPE.UPDATE_CONFIG,
    payload: ExternalConfigObjectType
};

export function updateConfig(value: ExternalConfigObjectType): UpdateConfigActionType {
    return {
        type: ACTION_TYPE.UPDATE_CONFIG,
        payload: value
    };
}
